import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { LoadingPage } from '@rfh-core/components'
import { CloseIcon } from '@rfh-core/icons'
import theme from '@rfh-core/theme'

import { ThemeConfig } from '../config/SpacingConfig'

type SpinnerProps = {
  isError?: boolean
}

const Spinner: FC<SpinnerProps> = ({ isError }: SpinnerProps) => {
  const { t } = useTranslation()

  return isError ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <CloseIcon
        sx={{
          height: '100%',
          color: `${theme.rfhColors.alertRed}`,
          marginTop: ThemeConfig.spacing.s,
          paddingBottom: ThemeConfig.spacing.s,
        }}
      />
      <Typography
        variant='body2'
        sx={{
          color: `${theme.rfhColors.alertRed}`,
        }}
      >
        {t('common.genericErrorShort')}
      </Typography>
    </Box>
  ) : (
    <Box data-testid='spinner'>
      <LoadingPage />
    </Box>
  )
}

export default Spinner
