import type { IAllSearchFilterFields } from '../interfaces'

export const pushNewFilterRule = <
  TKey extends keyof IAllSearchFilterFields,
  TValue extends IAllSearchFilterFields[TKey]
>(
  key: TKey,
  value: TValue,
  filterRules: string[]
): string[] => {
  let newRules = [...filterRules]
  let filterRule
  if (key === 'overLimit' && typeof value === 'boolean') {
    filterRule = constructFilterRuleForToggle(key, value)
    if (filterRule) {
      newRules.push(filterRule)
    }
  } else {
    newRules = [
      ...newRules,
      ...generateFilterString<IAllSearchFilterFields>(key, value, filterRules),
    ]
  }
  return newRules
}

export const constructFilterString = <T>(
  searchValues: T,
  addFilterRule: (key: keyof T, value: any, filterRules: string[]) => string[]
): string => {
  if (!searchValues) {
    return undefined
  }
  let filterRules: string[] = []
  // Loop over each value of the data and assign the correct filter rule
  for (const [key, value] of Object.entries(searchValues)) {
    if (!key || value == null) {
      continue
    }
    filterRules = addFilterRule(key as keyof T, value, filterRules)
  }

  if (filterRules.length === 0) return undefined

  return filterRules.filter(s => !!s).join(' and ')
}

const constructFilterRuleForToggle = (key: string, value: boolean): string => {
  let filterRule
  if (key === 'overLimit' && value) {
    filterRule = 'omzet ge limiet'
  }
  return filterRule
}

export const generateDefaultFilterString = (
  key: string,
  value: string | number,
  filterRules: string[]
): string[] => {
  let filterRule
  if (key) {
    if (typeof value === 'string') {
      filterRule = `contains(toLower(${key}),toLower('${value}'))`
    } else if (value !== 0) {
      filterRule = `${key} eq ${value}`
    }
    if (filterRule) {
      filterRules.push(filterRule)
    }
  }
  return filterRules
}

export const generateFilterString = <T extends Record<string, any>>(
  key: keyof T,
  value: T[keyof T],
  filterRules: string[]
): string[] => {
  if (key == null || value == null) {
    return filterRules
  }
  return generateDefaultFilterString(key as string, value, filterRules)
}
