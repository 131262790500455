import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

import { IBuyersCardView } from 'src/common/index'

import { defaultBuyerscard } from '../constants'

type StoreState = {
  buyersCard: IBuyersCardView
  oldBuyersCard: IBuyersCardView
  blockedIndicator: boolean
}

type Actions = {
  resetBuyersCardState: () => void
  setOldBuyersCard: (op: IBuyersCardView) => void
  setBuyersCard: (p: IBuyersCardView) => void
  updateBuyersCard: (np: Partial<IBuyersCardView>) => void
  setBlockedIndicator: (input: boolean) => void
}

const storeName = 'BuyersCardStore'

const initialStoreState: StoreState = {
  buyersCard: defaultBuyerscard,
  oldBuyersCard: defaultBuyerscard,
  blockedIndicator: false,
}

export const useBuyersCardStore = create<StoreState & Actions>()(
  devtools(
    persist(
      (set, get: () => any) => ({
        ...initialStoreState,
        resetBuyersCardState: () =>
          set(() => initialStoreState, false, 'resetBuyersCardState'),
        setBlockedIndicator: (input: boolean) =>
          set(
            () => ({ blockedIndicator: input }),
            false,
            'setBlockedIndicator'
          ),
        setOldBuyersCard: (op: IBuyersCardView) =>
          set(() => ({ oldBuyersCard: op }), false, 'setOldBuyersCard'),
        setBuyersCard: (p: IBuyersCardView) =>
          set(() => ({ buyersCard: p }), false, 'setBuyersCard'),
        updateBuyersCard: (np: Partial<IBuyersCardView>) => {
          const newBuyersCard = { ...get().buyersCard, ...np }
          set(() => ({ buyersCard: newBuyersCard }), false, 'updateBuyersCard')
        },
      }),
      {
        name: 'buyerscard-storage',
        storage: createJSONStorage(() => sessionStorage),
      }
    ),
    {
      name: storeName,
    }
  )
)
