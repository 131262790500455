import { type ChangeEvent } from 'react'

import { FormLabel, Switch, type SxProps } from '@mui/material'
import Grid from '@mui/material/Grid2'
import theme from '@rfh-core/theme'

type LabelSwitchProps = {
  checked?: boolean
  fieldName: string
  flowDirection?: 'row' | 'column' | 'column-reverse' | 'row-reverse'
  label?: string
  onChange?: (checked: boolean) => void
  sx?: SxProps
}

export default function LabelSwitch({
  checked = false,
  fieldName,
  flowDirection = 'column',
  label,
  onChange,
  sx = {},
}: Readonly<LabelSwitchProps>): JSX.Element {
  const handleChange = (_event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(!checked)
    }
  }

  return (
    <Grid
      container
      direction={flowDirection}
      justifyContent={'flex-start'}
      alignItems={'center'}
    >
      <FormLabel
        htmlFor={`id-${fieldName}`}
        sx={{
          color: theme.rfhColors.black,
          whiteSpace: 'nowrap',
          minWidth: 170,
          textAlign: 'center',
          ...sx,
        }}
      >
        {label}
      </FormLabel>
      <Switch
        checked={checked}
        color={'error'}
        onChange={handleChange}
        sx={{ minWidth: 44 }}
      />
    </Grid>
  )
}
