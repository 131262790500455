import { v4 } from 'uuid'

import * as packageJson from '../../../package.json'
import { isArray } from './isOfType'

interface ILocalStorageItem {
  id: number
}

export interface IOfflineEvent {
  id: string
  name: string
  args: any[]
}

/**
 * Check if the user has an active internet connection.
 */
export const isOnline = (): boolean => navigator.onLine

const getKey = (currentKey: string): string => {
  const version = packageJson.version
  if (
    currentKey.includes('okta') ||
    currentKey.includes(version) ||
    currentKey.includes('i18nextLng') ||
    currentKey.includes('rfh')
  ) {
    return currentKey
  }
  return `${version}-${currentKey}`
}

/**
 * Get an item stored in the offline store.
 * @param key The key with which the item is saved
 * @param id Get the item by id. This will be ignored when the stored data is not an array
 */
export const getLocalItem = <T>(key: string, id?: number | string): T => {
  const newKey = getKey(key)
  const item: any = JSON.parse(localStorage.getItem(newKey))

  return id && isArray(item)
    ? item.find(
        (i: Partial<ILocalStorageItem>): boolean =>
          i.id === parseInt(id as string, 10)
      )
    : item
}

/**
 * Store an item in the offline store.
 * @param key The key with which the item is saved
 * @param item Item to be stored
 */
export const setLocalItem = <T>(key: string, item: T): T => {
  const newKey = getKey(key)
  localStorage.setItem(newKey, JSON.stringify(item))
  return item
}

/**
 * Add an item to the offline store.
 * @param key The key with which the item is saved
 * @param item Item to be stored
 */
export const addLocalItem = <T>(key: string, item: T): T => {
  let localItem: any = getLocalItem<T>(key)

  localItem = isArray(localItem) ? [...localItem, item] : item

  setLocalItem<T>(key, localItem)

  return item
}

/**
 * Update an item in the offline store.
 * @param key The key with which the item is saved
 * @param item Item to be stored
 * @param id Update a item by id. This will be ignored when the stored data is not an array
 */
export const updateLocalItem = <T>(
  key: string,
  item: T,
  id?: string | number
): T => {
  let localItem: any = getLocalItem<T>(key)

  localItem =
    id && isArray(localItem)
      ? localItem.map(
          (i: Partial<ILocalStorageItem>): Partial<ILocalStorageItem> =>
            i.id === parseInt(id as string, 10) ? item : i
        )
      : item

  setLocalItem<T>(key, localItem)

  return item
}

/**
 * Remove item from the offline store.
 * @param key The key with which the item is saved
 * @param id Get a item by id. This will be ignored when the stored data is not an array
 */
export const removeLocalItem = <T>(
  key: string,
  id?: number | string
): T | void => {
  let item: any = getLocalItem<T>(key)
  if (id && isArray(item)) {
    item = item.filter((i: Partial<ILocalStorageItem>): boolean => i.id !== id)
    return setLocalItem<T>(key, item)
  }
  const currentKey = getKey(key)

  return localStorage.removeItem(currentKey)
}

/**
 * Add an event to be processed when the user will be back online.
 * @param name Name of the event, preferably the name of the function to be called
 * @param args Any arguments which the function needs to be executed with
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const addOfflineEvent = (name: string, ...args: any): void => {
  const event: IOfflineEvent = {
    id: v4(),
    name,
    args: [...args],
  }

  addLocalItem<IOfflineEvent>('offlineEvents', event)
}

export const clearStorage = (): void => {
  const version = packageJson.version
  Object.keys(localStorage).forEach(key => {
    if (
      !(
        key.includes('okta') ||
        key.includes(version) ||
        key.includes('i18nextLng') ||
        key.includes('rfh')
      )
    ) {
      localStorage.removeItem(key)
    }
  })
}
