import type { ForwardRefExoticComponent, RefAttributes } from 'react'

import { Box } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { GridToolbar, type GridToolbarProps } from '@mui/x-data-grid-premium'

export default function CustomGridToolbar(
  props: Readonly<
    ForwardRefExoticComponent<
      Omit<GridToolbarProps, 'ref'> & RefAttributes<HTMLDivElement>
    >
  >
): JSX.Element {
  return (
    <Grid container>
      <Box sx={{ flexGrow: 1 }} />
      <GridToolbar {...props} />
    </Grid>
  )
}
