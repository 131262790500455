import { addDays } from 'date-fns'

import { useCallback, useMemo } from 'react'

import { useStore } from 'zustand'

import {
  Entity,
  isDateToday,
  type IUserAccountDayLimit,
  snackbarUtils,
  useAddEntity,
  useDeleteEntity,
  utcNever,
  utcToday,
} from 'src/common'
import { usePurchaserStore } from 'src/features/purchasers'

import { defaultDayLimit } from '../lib/lib.constants'
import { usePurchaserLimitsStore } from '../stores'

type UsePurchaserLimitReturn = {
  submitPurchaserLimit: (inkoperIdentificatie?: string) => Promise<void>
  TODAY: Date
  TOMORROW: Date
  NEVER: Date
}

export default function usePurchaserLimit(): UsePurchaserLimitReturn {
  const TODAY = useMemo(() => utcToday(), [])
  const TOMORROW = useMemo(() => addDays(TODAY, 1), [TODAY])
  const NEVER = useMemo(() => utcNever(), [])
  const addEntity = useAddEntity<IUserAccountDayLimit>(
    Entity.IUserAccountDayLimit
  )

  const purchaser = useStore(usePurchaserStore, state => state.purchaser)
  const {
    dayLimit,
    isPurchaserLimitDirty,
    oldDayLimit,
    setDayLimit,
    setIsLoadingPurchaserLimits: setIsLoadingLimits,
  } = usePurchaserLimitsStore()

  const postDayLimit = useCallback(
    async (limit?: IUserAccountDayLimit): Promise<void> => {
      await addEntity.mutateAsync(limit)
    },
    [addEntity]
  )

  const deleteEntity = useDeleteEntity(Entity.IUserAccountDayLimit)

  const deletePreviousDayLimit = useCallback(async (): Promise<void> => {
    if (
      deleteEntity.isLoading ||
      !dayLimit?.userAccountDayLimitId ||
      dayLimit.userAccountDayLimitId <= 0
    ) {
      return Promise.resolve()
    }

    try {
      await deleteEntity.mutateAsync(dayLimit.userAccountDayLimitId)
    } catch (error) {
      snackbarUtils.error(`${error}`)
    }
  }, [deleteEntity, dayLimit])

  const submitPurchaserLimit = useCallback(
    async (inkoperIdentificatie?: string): Promise<void> => {
      let newDayLimit = { ...dayLimit }
      setIsLoadingLimits(true)

      if (
        isPurchaserLimitDirty() &&
        oldDayLimit.userAccountDayLimitAmount > 0
      ) {
        await deletePreviousDayLimit()

        if (
          newDayLimit.userAccountDayLimitAmount === 0 ||
          isDateToday(newDayLimit?.endDateTime)
        ) {
          newDayLimit = { ...defaultDayLimit }
          newDayLimit.userAccountName =
            inkoperIdentificatie ?? purchaser?.inkoperIdentificatie ?? ''
          setDayLimit(newDayLimit)
          return
        }
      }

      newDayLimit.userAccountDayLimitId = 0
      setDayLimit(newDayLimit)
      await postDayLimit(newDayLimit)
    },
    [
      dayLimit,
      deletePreviousDayLimit,
      isPurchaserLimitDirty,
      oldDayLimit.userAccountDayLimitAmount,
      postDayLimit,
      purchaser?.inkoperIdentificatie,
      setDayLimit,
      setIsLoadingLimits,
    ]
  )

  return { submitPurchaserLimit, TODAY, TOMORROW, NEVER }
}
