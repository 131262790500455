import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'

import { getFuncByEntity, isNumberEmpty } from 'src/common/lib'
import { useApiClient } from 'src/common/providers'

import { ApiClientGetFuncType, EntityType, FuncSort } from './own/lib.resources'
import { hasPropertyAndNotNull } from './own/lib.utils'
import { ApiClientGetFilter } from './own/types'

export const useFetchOne = <T>(
  filter: ApiClientGetFilter,
  entity: EntityType,
  enabled = true,
  extraOptions?: Partial<UseQueryOptions>
): UseQueryResult<T> => {
  const { apiClient } = useApiClient()
  const func = getFuncByEntity(FuncSort.get, entity) as ApiClientGetFuncType
  const queryResult = useQuery({
    queryKey: [entity, FuncSort.get, filter],
    queryFn: () => apiClient[func](filter.key ?? undefined) as any,
    enabled:
      hasPropertyAndNotNull(apiClient, func) &&
      filter &&
      !isNumberEmpty(filter.key) &&
      enabled,
    keepPreviousData: true,
    ...extraOptions,
  })

  return queryResult as UseQueryResult<T>
}
