export const DEFAULT_LANGUAGE = 'nl-NL'

const getLanguage = (): string =>
  localStorage.getItem('i18nextLng') || DEFAULT_LANGUAGE

const setLanguage = (lang: string): void => {
  localStorage.setItem('i18nextLng', lang)
}

export { getLanguage, setLanguage }
