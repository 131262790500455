import { IConfig } from 'src/common/interfaces/IConfig'

type RuntimeEnv =
  | 'loc'
  | 'test'
  | 'dev'
  | 'sys'
  | 'acc'
  | 'prd'
  | '#{Omgeving}#'

// These values {#...#} are replaced in the release pipeline
// If they're not replaced then the local test values are used
let envValue: RuntimeEnv = '#{Omgeving}#'
if (envValue.startsWith('#{')) {
  envValue = 'sys'
}
console.log(`Env: ${envValue}`)

disableDevToolsIf(envValue, ['prd'])

let apiHostValue = '#{ApiHost}#'
if (apiHostValue.startsWith('#{')) {
  //apiHostValue = 'https://localhost:44335'
  apiHostValue =
    'https://rru-tc-veilcontractbeheerservice-api.commstaging.royalfloraholland.com'
}

let redirectUriValue = '#{OktaRedirectUri}#'
if (redirectUriValue.startsWith('#{')) {
  redirectUriValue = 'http://localhost:3000/callback'
}

let clientIdValue = '#{OktaClientId}#'
if (clientIdValue.startsWith('#{')) {
  clientIdValue = '0oa3v6i6ke6CUTMxj0x7' //rru-veilcontractbeheer-dev
}

let domainValue = '#{OktaDomain}#'
if (domainValue.startsWith('#{')) {
  domainValue = 'https://identity-test.royalfloraholland.com/oauth2/default'
}

let issuerValue = '#{OktaIssuer}#'
if (issuerValue.startsWith('#{')) {
  issuerValue = 'https://identity-test.royalfloraholland.com/oauth2/default'
}

export const Config: IConfig = {
  env: envValue,
  api: {
    host: apiHostValue,
    path: '',
    apiVersion: '/odata/v1',
  },
  okta: {
    clientId: clientIdValue,
    domain: domainValue,
    issuer: issuerValue,
    redirectUri: redirectUriValue,
    scopes: [
      'openid',
      'profile',
      'email',
      `rru-veilcontractbeheer-api-${envValue}`,
    ],
    pkce: true,
  },
}

function disableDevToolsIf(
  currentEnv: RuntimeEnv,
  envsToDisable: RuntimeEnv[]
) {
  const currentValue = window.sessionStorage.getItem('dev-tools')
  const shouldBeEnabled = !envsToDisable.includes(currentEnv)
  if (shouldBeEnabled || currentValue === 'true') {
    window.sessionStorage.setItem('dev-tools', 'true')
    console.log('App DevTools enabled')
  } else {
    window.sessionStorage.setItem('dev-tools', 'false')
    console.log('App DevTools disabled')
  }
}
