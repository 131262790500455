import {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  ReactNode,
  useEffect,
  useState,
} from 'react'

import {
  FormControl,
  FormLabel,
  lighten,
  SxProps,
  TextField,
} from '@mui/material'
import Grid from '@mui/material/Grid2'

import { ELEMENT_HEIGHT, getStringValue } from 'src/common'

type LabelInputIconProps = {
  disabled?: boolean
  fieldName?: string
  inputValue?: string | number
  label?: string
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void
  onChange?: (event: any) => void
  sx?: SxProps
  children?: ReactNode
}

export default function LabelInputIcon({
  disabled = false,
  fieldName = '',
  inputValue = '',
  label = '',
  onBlur,
  onChange,
  sx,
  children,
}: Readonly<LabelInputIconProps>): JSX.Element {
  const [inputText, setInputText] = useState<string>(getStringValue(inputValue))

  const handleChangeLocally = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    setInputText(event.target.value)
  }

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (event.target?.value !== '') {
      onBlur(event)
    }
  }

  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onChange(event)
    }
  }

  useEffect(() => {
    setInputText(getStringValue(inputValue))
  }, [inputValue])

  return (
    <Grid container flexDirection={'row'} alignItems={'center'} sx={sx}>
      <Grid size={{ xs: 12, sm: 5 }}>
        <FormControl sx={{ ml: 0 }}>
          <FormLabel
            htmlFor={`id-${fieldName}`}
            sx={{
              color: theme => lighten(theme.rfhColors.black, 0.2),
              fontSize: '1em',
              pr: 0.5,
              maxHeight: ELEMENT_HEIGHT,
              ...sx,
            }}
          >
            {label}
          </FormLabel>
        </FormControl>
      </Grid>
      <Grid
        size={{ xs: 12, sm: children ? 5 : 7 }}
        sx={{ maxHeight: ELEMENT_HEIGHT }}
      >
        <FormControl fullWidth>
          <TextField
            id={`id-${fieldName}`}
            name={fieldName}
            value={inputText}
            disabled={disabled}
            onBlur={handleBlur}
            onKeyUp={handleKeyUp}
            onChange={handleChangeLocally}
          />
        </FormControl>
      </Grid>
      {children && (
        <Grid size={{ xs: 12, sm: 2 }} sx={{ maxHeight: ELEMENT_HEIGHT }}>
          {children}
        </Grid>
      )}
    </Grid>
  )
}
