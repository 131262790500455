import { utcToZonedTime } from 'date-fns-tz'

import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'

import {
  Alert,
  Box,
  Button,
  Container,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import Grid from '@mui/material/Grid2'
import { CheckIcon } from '@rfh-core/icons'

import CustomerInput from 'src/common/components/CustomerInput'
import { Divider } from 'src/common/components/Divider'
import { ThemeConfig } from 'src/common/config/SpacingConfig'
import {
  ApiClientGetFilter,
  Entity,
  useAddEntity,
  useFetchOne,
  usePathname,
  useUpdateEntityByKey,
} from 'src/common/hooks'
import { LabelCheckbox, LabelDate, useUserStore } from 'src/common/index'
import { IBuyersCard, IBuyersCardView } from 'src/common/services/client'
import { getUTCDate, snackbarUtils } from 'src/common/utils'

import { BuyersCardsDialogAvailablePlates } from '../components'
import { defaultBuyerscard } from '../constants'
import { useBuyersCardStore } from '../stores/BuyersCardStore'

export default function ManageBuyersCards(): JSX.Element {
  const { t } = useTranslation()
  const { user } = useUserStore()
  const history = useHistory()
  const { ADDING, urlParam } = usePathname('buyerscards')
  const {
    buyersCard,
    oldBuyersCard,
    setBuyersCard,
    setOldBuyersCard,
    updateBuyersCard,
    resetBuyersCardState,
  } = useBuyersCardStore()

  const buyerscardId = urlParam
  const filter: ApiClientGetFilter = { key: buyerscardId }

  const { data: fetchedBuyerscard } = useFetchOne<IBuyersCardView>(
    filter,
    Entity.BuyersCard,
    Boolean(buyerscardId)
  )

  const [isValidCustomerNumber, setIsValidCustomerNumber] =
    useState<boolean>(false)
  const [newCard, setNewCard] = useState<boolean>(false)

  const addEntity = useAddEntity<IBuyersCard>(Entity.BuyersCard)
  const updateEntity = useUpdateEntityByKey<IBuyersCard>(Entity.BuyersCard)

  const handleInputChangeNumber = (evt: any): void => {
    const { name, value } = evt.target
    updateBuyersCard({ [name]: value })
  }

  const handleKlantNummerChange = (value: number): void => {
    setIsValidCustomerNumber(Boolean(value))
    updateBuyersCard({ customerRfhRelationId: value })
  }

  const handleCheckedChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, checked } = event.target
    updateBuyersCard({ [name]: checked })
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target
    updateBuyersCard({ [name]: value })
  }

  const handleDateChange = (date: Date | null): void => {
    updateBuyersCard({
      endDate: date,
    })
  }

  const addBuyersCard = async () => {
    if (buyersCard.startDate > buyersCard.endDate) {
      snackbarUtils.error(t('buyersCards.addBuyersCards.invalidDate'))
      return
    }
    if (addEntity.isLoading || updateEntity.isLoading) {
      return Promise.resolve()
    }

    try {
      const body: IBuyersCard = {
        ...buyersCard,
        creationDateTime: ADDING
          ? getUTCDate(new Date())
          : oldBuyersCard?.creationDateTime,
        creationUser: ADDING ? user.sub : oldBuyersCard?.creationUser,
        mutationDateTime: getUTCDate(new Date()),
        mutationUser: user.sub,
      }
      let result
      if (ADDING) {
        result = await addEntity.mutateAsync(body)
      } else {
        await updateEntity.mutateAsync({ key: urlParam, body })
        result = buyersCard
      }

      if (result?.buyersCardId) {
        setNewCard(true)
        setBuyersCard(result)
        setOldBuyersCard(result)
        history.replace(`/dashboard/buyerscards/${result?.buyersCardId}/edit`)
      }
    } catch (error) {
      snackbarUtils.error(`${error}`)
    }
  }

  /**
   * useEffects
   */
  useEffect(() => {
    if (ADDING) {
      setBuyersCard(defaultBuyerscard)
      setOldBuyersCard(defaultBuyerscard)
    } else {
      if (fetchedBuyerscard?.isVervallen) {
        history.replace('/dashboard/buyerscards')
      }
      setOldBuyersCard(fetchedBuyerscard)
      setBuyersCard(fetchedBuyerscard)
    }
    return () => {
      resetBuyersCardState()
    }
  }, [
    ADDING,
    urlParam,
    setBuyersCard,
    setOldBuyersCard,
    fetchedBuyerscard,
    resetBuyersCardState,
    history,
  ])

  /*
  Render
  */
  return (
    <Container maxWidth='xl' sx={{ p: ThemeConfig.spacing.l }}>
      <Typography variant={'h1'} sx={{ fontSize: '35px' }}>
        {ADDING
          ? t('common.titles.addBuyersCards')
          : t('common.titles.buyersCards')}
      </Typography>
      {newCard ? (
        <Alert
          color='success'
          icon={<CheckIcon />}
          onClose={() => setNewCard(false)}
          title={t('buyersCards.addBuyersCards.addedBuyersCards')}
          sx={{ padding: '16px 0px !important' }}
        >
          <Link to={`/dashboard/buyerscard/${oldBuyersCard?.buyersCardId}`}>
            {t('common.clickHere')}
          </Link>{' '}
          {t('buyersCards.addBuyersCards.plateNumberAdded', {
            code: oldBuyersCard?.buyersCardId,
          })}
        </Alert>
      ) : null}
      <Box component='form' mt={2} width={'100%'} onSubmit={addBuyersCard}>
        <Grid container flexDirection={'row'} spacing={2}>
          <Grid size={{ sm: 6, xs: 12 }}>
            <CustomerInput
              showOnlyAvailableBuyers={true}
              changeCustomerNumber={handleKlantNummerChange}
              changeValid={setIsValidCustomerNumber}
              value={buyersCard?.customerRfhRelationId}
              disabled={!ADDING}
            />
          </Grid>
          <Grid size={{ sm: 6, xs: 12 }}>
            <TextField
              fullWidth
              label={t('buyersCards.plateNumber')}
              id='buyersCardId'
              name='buyersCardId'
              type='number'
              placeholder={t('buyersCards.plateNumber')}
              value={buyersCard?.buyersCardId}
              disabled={!ADDING}
              onChange={handleInputChangeNumber}
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position='end'>
                      <BuyersCardsDialogAvailablePlates
                        onChangePlate={handleInputChangeNumber}
                      />
                    </InputAdornment>
                  ),
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container flexDirection={'row'} spacing={2} mt={4}>
          <Grid
            container
            flexDirection={'row'}
            justifyContent={'flex-start'}
            my={2}
            size={{ xs: 12, sm: 6 }}
          >
            <Grid>
              <LabelCheckbox
                label='aalsmeer'
                fieldName='aalsmeer'
                flowDirection='column'
                value={buyersCard?.aalsmeer}
                onChange={handleCheckedChange}
              />
            </Grid>
            <Grid>
              <LabelCheckbox
                label='naaldwijk'
                fieldName='naaldwijk'
                flowDirection='column'
                value={buyersCard?.naaldwijk}
                onChange={handleCheckedChange}
              />
            </Grid>
            <Grid>
              <LabelCheckbox
                label='rijnsburg'
                fieldName='rijnsburg'
                flowDirection='column'
                value={buyersCard?.rijnsburg}
                onChange={handleCheckedChange}
              />
            </Grid>
            <Grid>
              <LabelCheckbox
                label='eelde'
                fieldName='eelde'
                flowDirection='column'
                value={buyersCard?.eelde}
                onChange={handleCheckedChange}
              />
            </Grid>
          </Grid>
          <Grid container flexDirection={'row'} mt={2} size={{ xs: 12, sm: 6 }}>
            <Grid size={{ xs: 12, sm: 6 }}>
              <LabelDate
                fieldName='startDate'
                value={utcToZonedTime(
                  new Date(
                    buyersCard?.startDate ?? defaultBuyerscard.startDate
                  ),
                  'CET'
                )}
                label={t('buyersCards.startDate')}
                flowDirection='column'
                disabled
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <LabelDate
                fieldName='endDate'
                value={utcToZonedTime(
                  new Date(buyersCard?.endDate ?? defaultBuyerscard.endDate),
                  'CET'
                )}
                label={t('buyersCards.endDate')}
                onChange={handleDateChange}
                flowDirection='column'
                disabled={ADDING}
              />
            </Grid>
          </Grid>
        </Grid>
        {!ADDING && (
          <>
            <Divider {...{ my: 4, width: '100%' }} />
            <Grid container flexDirection={'row'} spacing={2} mt={4}>
              <Grid container justifyContent={'start'} size={{ xs: 6 }}>
                <LabelCheckbox
                  label={t('buyersCards.blocked')}
                  fieldName='blockedindication'
                  value={buyersCard?.blockedindication}
                  flowDirection='column'
                  onChange={handleCheckedChange}
                  sx={{ fontWeight: 'bold' }}
                />
              </Grid>
              <Grid size={{ xs: 6 }}>
                <TextField
                  fullWidth
                  name='remark'
                  label={t('common.remark')}
                  value={buyersCard?.remark ?? ''}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
          </>
        )}
        <Divider {...{ my: 4, width: '100%' }} />
        <Grid
          container
          flexDirection={'row'}
          justifyContent={'flex-end'}
          gap={ThemeConfig.spacing.s}
          mt={5}
        >
          <Button variant='outlined' onClick={() => history.goBack()}>
            {t('common.back')}
          </Button>
          <Button
            variant='contained'
            disabled={!isValidCustomerNumber || !buyersCard?.buyersCardId}
            onClick={addBuyersCard}
          >
            {ADDING ? t('common.add') : t('common.save')}
          </Button>
        </Grid>
      </Box>
    </Container>
  )
}
