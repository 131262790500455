import { FC } from 'react'

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const FlagGB: FC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox='0 0 16 12' aria-label={'britains national flag'}>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-65.000000, -4399.000000)'>
        <g transform='translate(61.000000, 3171.000000)'>
          <g transform='translate(0.000000, 722.000000)'>
            <g transform='translate(4.000000, 506.000000)'>
              <rect fill='#003790' x='0' y='0' width='16' height='12' />
              <rect fill='#FFFFFF' x='0' y='4' width='16' height='4' />
              <polygon
                fill='#FFFFFF'
                transform='translate(8.000000, 6.000000) scale(-1, 1) translate(-8.000000, -6.000000) '
                points='0 1.0526123 14.9624023 12 16 12 16 11.0539551 1.0279541 0 0 0'
              />
              <polygon
                fill='#FFFFFF'
                points='0 1.0526123 14.9624023 12 16 12 16 11.0539551 1.0279541 0 0 0'
              />
              <rect fill='#FFFFFF' x='6' y='0' width='4' height='12' />
              <rect fill='#DA2B35' x='0' y='5' width='16' height='2' />
              <rect fill='#DA2B35' x='7' y='0' width='2' height='12' />
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
)

export default FlagGB
