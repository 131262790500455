export type FeatureName = 'INKOPERLIMIETEN' | 'KLANTLIMIETEN' | 'WIP'

export type Feature = {
  name: FeatureName
  enabled: boolean
  alwaysEnabled?: boolean
}

export const FEATURES: Record<FeatureName, Feature> = {
  INKOPERLIMIETEN: {
    name: 'INKOPERLIMIETEN' as FeatureName,
    enabled: true,
    alwaysEnabled: true,
  },
  KLANTLIMIETEN: {
    name: 'KLANTLIMIETEN' as FeatureName,
    enabled: true,
    alwaysEnabled: true,
  },
  WIP: {
    name: 'WIP',
    enabled: false,
    alwaysEnabled: false,
  },
}

export const featureList: Feature[] = Object.values(FEATURES)
export const featureNames = Object.keys(FEATURES) as FeatureName[]
