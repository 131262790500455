import { useTranslation } from 'react-i18next'
import {
  Route,
  RouteProps,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'

import { SecureRoute as OktaSecureRoute } from '@okta/okta-react'
import { LoadingPage } from '@rfh-core/components'

import { useUserStore } from 'src/common/stores/UserStore'
import { NotFound } from 'src/views'

import type { RenderProps } from './utils'

type SecureRouteProps = RouteProps & { enabled?: boolean }

export default function SecureRoute({
  component: Component,
  enabled = true,
  ...rest
}: Readonly<SecureRouteProps>): JSX.Element {
  const { user } = useUserStore()
  const history = useHistory()
  const location = useLocation()
  const match = useRouteMatch()
  const { t } = useTranslation()

  const getContent = (props: RenderProps): JSX.Element => {
    if (!user.isAuthenticated) {
      return <LoadingPage label={t('common.loading')} />
    }

    // user is authenticated but the "enabled" prop is passed as "false"
    // => nothing to do
    if (!enabled) {
      return
    }

    // "enabled" prop is not passed at all or passed as "true"
    // => the Component may be shown
    return (
      <Component
        history={history}
        location={location}
        match={match}
        {...props}
      />
    )
  }

  return enabled ? (
    <OktaSecureRoute
      {...(rest as any)}
      render={(props: RenderProps) => getContent(props)}
    />
  ) : (
    <Route component={NotFound} />
  )
}
