import { Location } from 'src/common/constants'
import {
  IInkoperKlokView,
  IInkoperPlaat,
  InkoperPlaat,
} from 'src/common/services/client'

import { InkoperPlaatRow } from '../types'

export const byVestigingCode = (
  p1: IInkoperPlaat,
  p2: IInkoperPlaat
): number => {
  if (Location[p1.vestigingCode] > Location[p2.vestigingCode]) {
    return 1
  } else if (Location[p1.vestigingCode] < Location[p2.vestigingCode]) {
    return -1
  }
  return 0
}

export const byPlateNumber = (
  row1: InkoperPlaatRow | InkoperPlaat,
  row2: InkoperPlaatRow | InkoperPlaat
): number => {
  if (row1.koperPlaatNummer > row2.koperPlaatNummer) {
    return 1
  }
  if (row1.koperPlaatNummer < row2.koperPlaatNummer) {
    return -1
  }
  return 0
}

export const byClockShortName = (
  clock1: IInkoperKlokView,
  clock2: IInkoperKlokView
): number => {
  const cshn1 = clock1.klokKorteNaam
  const cshn2 = clock2.klokKorteNaam
  // First, compare the letters
  const letter1 = cshn1.charAt(0)
  const letter2 = cshn2.charAt(0)
  const compareLettersResult = letter1.localeCompare(letter2)
  if (compareLettersResult !== 0) return compareLettersResult
  // If the letters are the same, compare the numbers
  const number1 = Number(cshn1.slice(1))
  const number2 = Number(cshn2.slice(1))
  return number1 > number2 ? 1 : -1
}
