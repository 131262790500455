import {
  ChangeEvent,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useEffect,
} from 'react'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Button,
  FormControl,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import Grid from '@mui/material/Grid2'

import {
  constructFilterString,
  ELEMENT_HEIGHT,
  type IPurchaserFilterInput,
  LabelBooleanDropdown,
  LabelDropdownText,
  MAX_INPUT_WIDTH,
  replaceEmptyValueWithUndefined,
  ThemeConfig,
  useFilter,
  useGridStore,
} from 'src/common'

import { defaultFilter, SourceValues } from '../constants/constants'
import { generateFilterString, isInputValid } from '../lib'
import { usePurchasersFilterStore } from '../stores'
import { type FilterProps } from '../types'

export default function PurchasersFilter({
  resetFilter: resetFilterString,
}: Readonly<FilterProps>): JSX.Element {
  const keyPurchasersFilterValue = 'PurchasersFilter-values'
  const { setShouldPaginationBeReset } = useGridStore()
  const {
    setPurchasersFilter,
    setPurchasersFilterInitialized,
    resetPurchasersFilterStoreState: resetPurchasersFilterState,
  } = usePurchasersFilterStore()

  const setFilterString = useCallback(
    (newFilter: IPurchaserFilterInput): void => {
      const newFilterString = constructFilterString(
        newFilter,
        generateFilterString
      )
      setPurchasersFilter(newFilterString)
    },
    [setPurchasersFilter]
  )

  const { t } = useTranslation()

  const { filter, clear, handleChange, handleRequest } =
    useFilter<IPurchaserFilterInput>({
      defaultFilter,
      resetFilterString,
      setFilterString,
      cacheKey: keyPurchasersFilterValue,
    })

  const handleInputChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = target
      if (!isInputValid(name, value)) return
      const input = name === 'koperNummer' ? Number(value) : value

      handleChange(
        name as keyof IPurchaserFilterInput,
        replaceEmptyValueWithUndefined(input)
      )
    },
    [handleChange]
  )
  const handleClear = useCallback(() => {
    resetFilterString()
    resetPurchasersFilterState()
    clear()
    setFilterString(defaultFilter)
  }, [clear, resetFilterString, resetPurchasersFilterState, setFilterString])

  const handleSearch = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setFilterString(filter)
      handleRequest(event)
      setShouldPaginationBeReset(true)
    },
    [filter, handleRequest, setFilterString, setShouldPaginationBeReset]
  )

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        handleRequest(event)
        setShouldPaginationBeReset(true)
      }
    },
    [handleRequest, setShouldPaginationBeReset]
  )

  const handleInputChangeSelect = ({ target }: SelectChangeEvent<string>) => {
    const { name, value } = target
    const isDefined = ['true', 'false'].includes(value)
    const isTrue = isDefined && value === 'true'
    if (isDefined) {
      handleChange(name as keyof IPurchaserFilterInput, isTrue)
    } else {
      handleChange(name as keyof IPurchaserFilterInput, undefined)
    }
  }

  useEffect(() => {
    setFilterString(filter)
    setPurchasersFilterInitialized(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid
      container
      flexDirection={'row'}
      justifyContent={'space-between'}
      alignItems={'end'}
      minHeight={ELEMENT_HEIGHT}
      mb={ThemeConfig.spacing.sm}
    >
      <Grid
        container
        flexDirection={'row'}
        justifyContent={'justify-start'}
        alignItems={'end'}
        gap={ThemeConfig.spacing.s}
      >
        <Grid maxWidth={MAX_INPUT_WIDTH}>
          <FormControl fullWidth>
            <TextField
              label={t('purchaser.purchaserIdentification')}
              name='inkoperIdentificatie'
              value={filter.inkoperIdentificatie ?? ''}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
          </FormControl>
        </Grid>
        <Grid maxWidth={MAX_INPUT_WIDTH}>
          <FormControl fullWidth>
            <TextField
              label={t('purchaser.buyerNumber')}
              name='koperNummer'
              value={filter.koperNummer ?? ''}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
          </FormControl>
        </Grid>
        <Grid maxWidth={MAX_INPUT_WIDTH}>
          <FormControl fullWidth>
            <TextField
              label={t('purchaser.buyerName')}
              name='koperNaam'
              value={filter.koperNaam ?? ''}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
          </FormControl>
        </Grid>
        <Grid maxWidth={MAX_INPUT_WIDTH}>
          <FormControl fullWidth>
            <TextField
              label={t('buyersCards.plateNumber')}
              name='inkoperPlatenLijst'
              value={filter.inkoperPlatenLijst ?? ''}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
          </FormControl>
        </Grid>
        <Grid>
          <LabelDropdownText
            label={t('purchaser.sourceCode')}
            labelVisible
            items={[
              { name: t('purchaser.all'), code: undefined },
              { name: SourceValues.FDY, code: 'FDY' },
              { name: SourceValues.RRU, code: 'RRU' },
            ]}
            value={filter.sourceCode}
            fieldName='sourceCode'
            onChange={({ target }) => handleChange('sourceCode', target.value)}
            sx={{
              mb: '0.45rem',
              maxHeight: ELEMENT_HEIGHT,
              fontSize: '1em',
            }}
          />
        </Grid>
        <Grid>
          <LabelBooleanDropdown
            label={t('buyersCards.blocked')}
            onChange={handleInputChangeSelect}
            inputValue={filter.blokkeerIndicatie}
            fieldname={'blokkeerIndicatie'}
            labelVisible
          />
        </Grid>
        <Grid>
          <LabelBooleanDropdown
            label={t('buyersCards.expired')}
            onChange={handleInputChangeSelect}
            inputValue={filter.isVervallen}
            fieldname={'isVervallen'}
            labelVisible
          />
        </Grid>
      </Grid>
      <Box flexGrow={1} />
      <Grid
        container
        flexDirection={'row'}
        justifyContent={'justify-end'}
        alignItems={'end'}
        gap={ThemeConfig.spacing.s}
      >
        <Button
          variant='outlined'
          onClick={handleClear}
          sx={{ height: ELEMENT_HEIGHT }}
        >
          {t('common.clear')}
        </Button>
        <Button
          variant='contained'
          type='submit'
          onClick={handleSearch}
          sx={{ height: ELEMENT_HEIGHT }}
        >
          {t('common.search')}
        </Button>
      </Grid>
    </Grid>
  )
}
