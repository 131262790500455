import type { DebouncedFunc } from 'lodash'
import debounce from 'lodash.debounce'

import { ChangeEvent } from 'react'

type DebouncedInputFunc = {
  debounced: (
    fn: (event: ChangeEvent<HTMLInputElement>) => void,
    delay?: number
  ) => DebouncedFunc<(event: ChangeEvent<HTMLInputElement>) => void>
}

export const useDebouncedInput = (): DebouncedInputFunc => {
  const debounced = (
    fn: (event: ChangeEvent<HTMLInputElement>) => void,
    delay = 1000
  ) => debounce(fn, delay)

  return { debounced }
}
