import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { Switch } from '@mui/material'
import Grid from '@mui/material/Grid2'

import { RoleOptions, useUserStore } from 'src/common/stores/UserStore'

import Property from './Property'

type OverRulingProps = {
  minHeight: number
  p: number
}

export default function OverRuling({
  minHeight,
  p,
}: Readonly<OverRulingProps>): JSX.Element {
  const { user, setRole } = useUserStore()
  const { t } = useTranslation()

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = target
    setRole(name as RoleOptions, checked)
  }

  return (
    <Grid
      container
      flexDirection={'column'}
      p={p}
      minHeight={minHeight}
      sx={{ backgroundColor: 'white' }}
    >
      <Property
        name={t('profilePage.applicationManagerRole')}
        value={
          <Switch
            name='isApplicationManager'
            checked={user.isApplicationManager}
          />
        }
      />
      <Property
        name={t('profilePage.accountManagementRole')}
        value={
          <Switch
            name='isAccountManagement'
            checked={user.isAccountManagement}
            onChange={handleChange}
          />
        }
      />
      <Property
        name={t('profilePage.contractManagementRole')}
        value={
          <Switch
            name='isContractBeheerder'
            checked={user.isContractBeheerder}
            onChange={handleChange}
          />
        }
      />
    </Grid>
  )
}
