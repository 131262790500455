import {
  type ChangeEvent,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useEffect,
} from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  FormControl,
  formLabelClasses,
  Stack,
  TextField,
} from '@mui/material'

import {
  constructFilterString,
  ELEMENT_HEIGHT,
  type FilterStringProps,
  type IAllSearchFilterFields,
  LabelSwitch,
  pushNewFilterRule,
  replaceEmptyValueWithUndefined,
  ThemeConfig,
  useFilter,
  useGridStore,
} from 'src/common'

import { defaultFilter } from '../lib'
import { useClientLimitsStore } from '../stores'

export default function ClientLimitsFilter({
  updateFilterString,
  resetFilterString,
}: Readonly<FilterStringProps>): JSX.Element {
  const CACHE_KEY = 'client-limits-filter'
  const { t } = useTranslation()
  const { setShouldPaginationBeReset } = useGridStore()
  const { resetClientLimitsStoreState } = useClientLimitsStore()

  const setFilterString = useCallback(
    (newFilter: IAllSearchFilterFields): void => {
      const newFilterString = constructFilterString(
        newFilter,
        pushNewFilterRule
      )
      updateFilterString(newFilterString)
    },
    [updateFilterString]
  )

  const { filter, clear, handleChange, handleRequest } =
    useFilter<IAllSearchFilterFields>({
      defaultFilter,
      resetFilterString,
      setFilterString,
      cacheKey: CACHE_KEY,
    })

  const changeInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target
      const input = name === 'klantNummer' ? Number(value) : value

      handleChange(
        name as keyof IAllSearchFilterFields,
        replaceEmptyValueWithUndefined(input)
      )
    },
    [handleChange]
  )

  const clearFilter = useCallback(() => {
    resetClientLimitsStoreState()
    clear()
  }, [clear, resetClientLimitsStoreState])

  const search = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setFilterString(filter)
      handleRequest(event)
      setShouldPaginationBeReset(true)
    },
    [filter, handleRequest, setFilterString, setShouldPaginationBeReset]
  )

  const searchIfEnterPressed = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        handleRequest(event)
        setShouldPaginationBeReset(true)
      }
    },
    [handleRequest, setShouldPaginationBeReset]
  )

  const toggleOverLimit = (checked: boolean) => {
    setFilterString({ ...filter, overLimit: checked })
    handleChange('overLimit', checked)
    setShouldPaginationBeReset(true)
  }

  useEffect(() => {
    setFilterString(filter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Stack
      flexDirection={'row'}
      justifyContent={'space-between'}
      alignItems={'end'}
      minHeight={ELEMENT_HEIGHT}
      marginBottom={ThemeConfig.spacing.sm}
    >
      <Stack
        flexDirection={'row'}
        justifyContent={'justify-start'}
        gap={ThemeConfig.spacing.s}
        alignItems={'end'}
        minWidth={600}
      >
        <FormControl fullWidth>
          <TextField
            label={t('client.clientNumber')}
            name='klantNummer'
            value={filter.klantNummer ?? ''}
            onChange={changeInput}
            onKeyDown={searchIfEnterPressed}
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            label={t('client.clientName')}
            name='klantNaam'
            value={filter.klantNaam ?? ''}
            onChange={changeInput}
            onKeyDown={searchIfEnterPressed}
          />
        </FormControl>
        <FormControl sx={{ mb: 2 }}>
          <LabelSwitch
            fieldName={'limiet'}
            label={t('client.toggleLabel')}
            checked={filter.overLimit}
            onChange={toggleOverLimit}
            sx={{
              minWidth: 100,
              maxWidth: 180,
              [`&.${formLabelClasses.root}`]: {
                fontSize: '100%',
                fontWeight: 'bold',
                fontFamily: 'Halyard-display',
                lineHeight: '1.5rem',
                mb: 3.75,
              },
            }}
          />
        </FormControl>
      </Stack>

      <Stack
        flexDirection={'row'}
        justifyContent={'justify-end'}
        alignItems={'end'}
        gap={ThemeConfig.spacing.s}
      >
        <Button
          variant='outlined'
          onClick={clearFilter}
          sx={{
            height: ELEMENT_HEIGHT,
            mr: ThemeConfig.spacing.sm,
          }}
        >
          {t('common.clear')}
        </Button>
        <Button
          variant='contained'
          type='submit'
          onClick={search}
          sx={{ height: ELEMENT_HEIGHT }}
        >
          {t('common.search')}
        </Button>
      </Stack>
    </Stack>
  )
}
