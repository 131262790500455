/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createContext, useCallback, useContext, useMemo } from 'react'

import { useOktaAuth } from '@okta/okta-react'

import type { ChildrenType } from './types'

export type AuthenticationContextType = {
  login: () => void
  logout: () => void
}

export const AuthenticationContext =
  createContext<AuthenticationContextType>(undefined)

/**
 * Use this hook to access the login and logout functions
 * @example const { login, logout } = useAuthentication()
 */
export const useAuthentication = () => {
  const context = useContext(AuthenticationContext)

  if (context === undefined) {
    throw new Error(
      'useAuthentication hook was used outside of its context provider'
    )
  }

  return context
}

/**
 * This provider is used to give all the child's access to the useAuthentication hook that can be used
 * to login or logout an user. By managing them in one provider we are able to keep is maintainable.
 */
export const AuthenticationProvider = ({
  children,
}: ChildrenType): JSX.Element => {
  const { oktaAuth } = useOktaAuth()

  const login = useCallback(() => {
    oktaAuth.signInWithRedirect()
  }, [oktaAuth])

  const logout = useCallback(() => {
    oktaAuth.tokenManager.clear()
    oktaAuth.signOut({
      postLogoutRedirectUri: window.location.origin,
    })
  }, [oktaAuth])

  const returnProps = useMemo(
    () => ({
      login,
      logout,
    }),
    [login, logout]
  )

  return (
    <AuthenticationContext.Provider value={returnProps}>
      {children}
    </AuthenticationContext.Provider>
  )
}
