import merge from 'lodash/merge'

import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  GridColDef,
  GridColumnOrderChangeParams,
  GridColumnVisibilityModel,
  GridEventListener,
  GridInitialState,
  GridPaginationModel,
  GridSortModel,
  useGridApiRef,
} from '@mui/x-data-grid-premium'
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium'
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium'

import { allGridsDefaults } from 'src/common/constants'
import {
  calcNewPaginationModel,
  getCurrentColumnVisibilityModel,
  getCurrentOrderedFieldsModel,
  getCurrentPaginationModel,
  getCurrentSortModel,
  noOp,
  swapColumns,
} from 'src/common/lib'
import { useGridStore } from 'src/common/stores'
import { GridName } from 'src/common/types'
import { getLocalItem, setLocalItem } from 'src/common/utils'

type UseDataGridReturn = {
  apiRef: MutableRefObject<GridApiPremium>
  orderedVisibleColumns: GridColDef[]
  gridState: GridInitialStatePremium
  saveChangedPaginationModel: (_paginationModel: GridPaginationModel) => void
  saveChangedSortModel: (_sortModel: GridSortModel) => void
  saveChangedColumnVisibilityModel: (
    columnVisibilityModel: GridColumnVisibilityModel
  ) => void
  saveChangedColumnOrder: (_params: GridColumnOrderChangeParams) => void
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export default function useDataGrid({
  gridName,
  columns,
}: {
  gridName: GridName
  columns: GridColDef[]
}): UseDataGridReturn {
  const gridDefaults = allGridsDefaults[gridName]
  const { CACHE_KEY } = gridDefaults
  const apiRef = useGridApiRef()
  const {
    setGridChanged,
    setShouldPaginationBeReset,
    shouldPaginationBeReset,
  } = useGridStore()
  const [dataGridRestored, setDataGridRestored] = useState<boolean>(false)
  const currentPaginationModel = getCurrentPaginationModel(CACHE_KEY)
  const currentSortModel = getCurrentSortModel(CACHE_KEY)
  const currentColumnVisibilityModel =
    getCurrentColumnVisibilityModel(gridDefaults)
  const currentOrderedFieldsModel = getCurrentOrderedFieldsModel(gridDefaults)

  const defaultGridState = useMemo(
    () => ({
      pagination: { paginationModel: { ...currentPaginationModel } },
      sorting: { sortModel: currentSortModel },
      columns: {
        columnVisibilityModel: currentColumnVisibilityModel,
        orderedFields: currentOrderedFieldsModel,
      },
    }),
    [
      currentColumnVisibilityModel,
      currentOrderedFieldsModel,
      currentPaginationModel,
      currentSortModel,
    ]
  )

  const [gridState, setGridState] = useState<GridInitialState>(
    () => defaultGridState
  )

  const orderedVisibleColumns: GridColDef[] = useMemo(
    () =>
      gridState?.columns?.orderedFields
        .map(field => columns.find(col => col.field === field))
        .filter(col => Boolean(col?.field))
        .map(col => ({
          ...col,
          hide: !gridState.columns.columnVisibilityModel[col.field],
        })) || [],
    [
      columns,
      gridState?.columns?.columnVisibilityModel,
      gridState?.columns?.orderedFields,
    ]
  )

  //#region Snapshot
  const savePersistedGridState = useCallback(
    (changedState?: GridInitialState) => {
      if (!dataGridRestored) return
      const prevState = apiRef.current.exportState() ?? defaultGridState
      const nextState = merge({}, prevState, changedState)
      setGridState(nextState)
      setLocalItem<GridInitialState>(CACHE_KEY, nextState)
    },
    [CACHE_KEY, apiRef, dataGridRestored, defaultGridState]
  )

  const restorePersistedGridState = useCallback(() => {
    const persistedGridState = getLocalItem<GridInitialState>(CACHE_KEY)
    apiRef.current.restoreState(persistedGridState ?? defaultGridState)
    setDataGridRestored(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CACHE_KEY, apiRef])
  //#endregion Snapshot

  //#region Handlers
  const resetPagination = useCallback(() => {
    apiRef.current?.setPage(0)
    const newGridState = {
      ...gridState,
      pagination: { paginationModel: { ...currentPaginationModel, page: 0 } },
    }
    savePersistedGridState(newGridState)
    setGridChanged(gridName)
    setShouldPaginationBeReset(false)
  }, [
    apiRef,
    currentPaginationModel,
    gridName,
    gridState,
    savePersistedGridState,
    setGridChanged,
    setShouldPaginationBeReset,
  ])

  const saveChangedPaginationModel = useCallback(
    (changedPaginationModel: GridPaginationModel) => {
      const prevPaginationModel: GridPaginationModel = {
        ...defaultGridState?.pagination?.paginationModel,
        ...gridState?.pagination?.paginationModel,
      }
      const nextPaginationModel = calcNewPaginationModel(
        prevPaginationModel,
        changedPaginationModel
      )
      const newGridState = {
        ...defaultGridState,
        pagination: { paginationModel: nextPaginationModel },
      }
      savePersistedGridState(newGridState)
      setGridChanged(gridName)
    },
    [
      defaultGridState,
      gridName,
      gridState?.pagination?.paginationModel,
      savePersistedGridState,
      setGridChanged,
    ]
  )

  const saveChangedSortModel = useCallback(
    (_sortModel: GridSortModel) => {
      setShouldPaginationBeReset(true)
      savePersistedGridState()
      setGridChanged(gridName)
    },
    [
      gridName,
      savePersistedGridState,
      setGridChanged,
      setShouldPaginationBeReset,
    ]
  )

  const saveChangedColumnVisibilityModel = useCallback(
    (columnVisibilityModel: GridColumnVisibilityModel) => {
      const changedState = {
        ...gridState,
        columns: {
          ...gridState.columns,
          columnVisibilityModel,
        },
      }
      savePersistedGridState(changedState)
    },
    [gridState, savePersistedGridState]
  )

  const saveChangedColumnOrder = useCallback(
    (params: GridColumnOrderChangeParams) => {
      const fields = orderedVisibleColumns.map(col => col.field)
      const orderedFields: string[] = swapColumns({
        fields,
        fieldToSwap: params.column.field,
        targetIndex: params.targetIndex,
      })
      const changedState = {
        ...gridState,
        columns: { ...gridState.columns, orderedFields },
      }
      savePersistedGridState(changedState)
    },
    [gridState, orderedVisibleColumns, savePersistedGridState]
  )
  //#endregion Handlers

  //#region Hooks
  useEffect(() => {
    restorePersistedGridState()

    return () => {
      savePersistedGridState()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (shouldPaginationBeReset) {
      resetPagination()
      setShouldPaginationBeReset(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldPaginationBeReset])

  useEffect(() => {
    const handleRowDragStart: GridEventListener<'rowDragStart'> = () => {
      // AR: not sure yet what (else) to do here as doing nothing works 100%
      noOp()
    }
    // This will manage proper unsubscribe logic to prevent duplicate event handlers
    return apiRef.current.subscribeEvent('rowDragStart', handleRowDragStart)
  }, [apiRef])
  //#endregion Hooks

  return {
    apiRef,
    orderedVisibleColumns,
    gridState,
    saveChangedPaginationModel,
    saveChangedSortModel,
    saveChangedColumnVisibilityModel,
    saveChangedColumnOrder,
  }
}
