import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, FormControl, Stack, TextField } from '@mui/material'

import {
  constructFilterString,
  ELEMENT_HEIGHT,
  type FilterStringProps,
  generateFilterString,
  type IPurchaserLimitsFilterInput,
  replaceEmptyValueWithUndefined,
  ThemeConfig,
  useFilter,
  useGridStore,
} from 'src/common'

import { defaultFilter, isInputValid } from '../lib'
import { usePurchaserLimitsStore } from '../stores'

export default function PurchaserLimitsFilter({
  updateFilterString,
  resetFilterString,
}: Readonly<FilterStringProps>): JSX.Element {
  const CACHE_KEY = 'purchaser-limits-filter'
  const { t } = useTranslation()
  const { setShouldPaginationBeReset } = useGridStore()
  const { resetPurchaserLimitsState: resetLimitsState } =
    usePurchaserLimitsStore()

  const setFilterString = useCallback(
    (newFilter: IPurchaserLimitsFilterInput): void => {
      const newFilterString = constructFilterString(
        newFilter,
        generateFilterString<IPurchaserLimitsFilterInput>
      )
      updateFilterString(newFilterString)
    },
    [updateFilterString]
  )

  const { filter, clear, handleChange, handleRequest } =
    useFilter<IPurchaserLimitsFilterInput>({
      defaultFilter,
      resetFilterString,
      setFilterString,
      cacheKey: CACHE_KEY,
    })

  const handleInputChange = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = target
      if (!isInputValid(name, value)) return
      const input =
        name === 'customerAdministrationNumber' ? Number(value) : value

      handleChange(
        name as keyof IPurchaserLimitsFilterInput,
        replaceEmptyValueWithUndefined(input)
      )
    },
    [handleChange]
  )

  const handleClear = useCallback(() => {
    resetFilterString()
    resetLimitsState()
    clear()
    setFilterString(defaultFilter)
  }, [clear, resetFilterString, resetLimitsState, setFilterString])

  const handleSearch = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setFilterString(filter)
      handleRequest(event)
      setShouldPaginationBeReset(true)
    },
    [filter, handleRequest, setFilterString, setShouldPaginationBeReset]
  )

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        handleRequest(event)
        setShouldPaginationBeReset(true)
      }
    },
    [handleRequest, setShouldPaginationBeReset]
  )

  useEffect(() => {
    setFilterString(filter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Stack
      flexDirection={'row'}
      justifyContent={'space-between'}
      alignItems={'end'}
      minHeight={ELEMENT_HEIGHT}
      marginBottom={ThemeConfig.spacing.sm}
    >
      <Stack
        flexDirection={'row'}
        justifyContent={'justify-start'}
        gap={ThemeConfig.spacing.s}
        alignItems={'end'}
      >
        <FormControl fullWidth>
          <TextField
            label={t('purchaser.purchaserIdentification')}
            name='userAccountName'
            value={filter.userAccountName ?? ''}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            label={t('purchaser.buyerNumber')}
            name='customerAdministrationNumber'
            value={filter.customerAdministrationNumber ?? ''}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            label={t('purchaser.buyerName')}
            name='customerName'
            value={filter.customerName ?? ''}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
        </FormControl>
      </Stack>

      <Stack
        flexDirection={'row'}
        justifyContent={'justify-end'}
        alignItems={'end'}
        gap={ThemeConfig.spacing.s}
      >
        <Button
          variant='outlined'
          onClick={handleClear}
          sx={{
            height: ELEMENT_HEIGHT,
            mr: ThemeConfig.spacing.sm,
          }}
        >
          {t('common.clear')}
        </Button>
        <Button
          variant='contained'
          type='submit'
          onClick={handleSearch}
          sx={{ height: ELEMENT_HEIGHT }}
        >
          {t('common.search')}
        </Button>
      </Stack>
    </Stack>
  )
}
