import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Container, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'

import {
  allGridsDefaults,
  Divider,
  Entity,
  getCurrentPaginationModel,
  getCurrentSortModel,
  getOrderBy,
  getSkip,
  getTop,
  type GridName,
  type IUserAccountDayLimitView,
  ThemeConfig,
  useFetchMany,
  useGridStore,
} from 'src/common'

import { PurchaserLimitsFilter, PurchaserLimitsGrid } from '../components'
import { usePurchaserLimitsStore } from '../stores'

export default function PurchaserLimits(): JSX.Element {
  const { CACHE_KEY } = allGridsDefaults.purchaserLimits
  const GRID_NAME: GridName = 'purchaserLimits'
  const { t } = useTranslation()
  const {
    mustRefreshPurchaserLimitsData,
    resetPurchaserLimitsState,
    setMustRefreshPurchaserLimitsData,
  } = usePurchaserLimitsStore()
  const { setGridChanged, setShouldPaginationBeReset } = useGridStore()
  const [filter, setFilter] = useState<string>(undefined)

  const updateFilter = (value: string) => {
    setFilter(value)
    setShouldPaginationBeReset(true)
    setGridChanged(GRID_NAME)
  }

  const refresh = useCallback(
    () => setMustRefreshPurchaserLimitsData(true),
    [setMustRefreshPurchaserLimitsData]
  )

  const resetGridState = useCallback(() => {
    refresh()
    setShouldPaginationBeReset(true)
    setGridChanged(GRID_NAME)
  }, [refresh, setGridChanged, setShouldPaginationBeReset])

  const resetFilter = () => {
    setFilter(undefined)
    setShouldPaginationBeReset(true)
    setGridChanged(GRID_NAME)
  }

  const {
    data: result,
    isLoading,
    refetch,
  } = useFetchMany<IUserAccountDayLimitView>(
    {
      top: getTop(getCurrentPaginationModel(CACHE_KEY)),
      skip: getSkip(getCurrentPaginationModel(CACHE_KEY)),
      count: true,
      orderby: getOrderBy(getCurrentSortModel(CACHE_KEY)),
      filter: filter?.length > 0 ? filter : undefined,
    },
    Entity.IUserAccountDayLimitView
  )

  useEffect(() => {
    resetPurchaserLimitsState()
    resetGridState()
    refresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (mustRefreshPurchaserLimitsData) {
      refetch<IUserAccountDayLimitView>().then(() => {
        setMustRefreshPurchaserLimitsData(false)
      })
    }
  }, [
    mustRefreshPurchaserLimitsData,
    refetch,
    setMustRefreshPurchaserLimitsData,
  ])

  return (
    <Container maxWidth='xl' sx={{ maxWidth: '1920px !important' }}>
      <Grid container sx={{ width: '100%' }}>
        <Grid size={{ xs: 12 }}>
          <Typography
            variant='h1'
            sx={{
              fontSize: '2.5rem',
              my: `${ThemeConfig.spacing.sm * 8}px`,
            }}
          >
            {t('common.titles.purchaserLimitsReadOnly')}
          </Typography>
        </Grid>
        <Grid size={{ xs: 12 }} mt={2}>
          <PurchaserLimitsFilter
            updateFilterString={updateFilter}
            resetFilterString={resetFilter}
          />
        </Grid>
        <Divider {...{ marginTop: 2, width: '100%' }} />
        <Grid size={{ xs: 12 }} mt={2}>
          <PurchaserLimitsGrid
            isLoading={isLoading}
            refresh={refresh}
            rows={result?.records ?? []}
            totalCount={result?.count ?? 0}
          />
        </Grid>
      </Grid>
    </Container>
  )
}
