import { v4 as getRandomID } from 'uuid'

import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  selectClasses,
  SxProps,
} from '@mui/material'
import theme from '@rfh-core/theme'

import type { Item } from 'src/common/types'

type LabelDropdownTextProps = {
  onChange?: (event: SelectChangeEvent<string>) => void
  label?: string
  labelVisible?: boolean
  disabled?: boolean
  fieldName?: string
  items?: Item<string>[]
  value?: string
  sx?: SxProps
}

export default function LabelDropdownText({
  disabled,
  fieldName,
  label,
  labelVisible = false,
  onChange,
  items,
  value,
  sx,
}: Readonly<LabelDropdownTextProps>): JSX.Element {
  const { t } = useTranslation()
  const options = useMemo(() => {
    if (items.length === 0) {
      return (
        <MenuItem key={getRandomID()} disabled value='' sx={{ minWidth: 160 }}>
          {t('common.noResults')}
        </MenuItem>
      )
    }

    return items.map(item => (
      <MenuItem
        key={getRandomID()}
        value={item?.code ?? ''}
        sx={{ minWidth: 160 }}
      >
        {item?.name}
      </MenuItem>
    ))
  }, [t, items])

  return (
    <FormControl fullWidth>
      {labelVisible && (
        <FormLabel
          htmlFor={`id-${fieldName}`}
          sx={{
            fontFamily: 'halyard-display',
            fontWeight: 'bold',
            fontSize: '1rem',
            lineHeight: '1.5rem',
            color: theme.rfhColors.black,
            ...sx,
          }}
        >
          {label}
        </FormLabel>
      )}
      <Select
        value={value ?? ''}
        defaultValue={''}
        onChange={onChange}
        disabled={disabled}
        id={`id-${fieldName}`}
        aria-label={label}
        name={fieldName}
        autoWidth
        sx={{
          minWidth: 160,
          width: '100%',
          bgcolor: theme.rfhColors.white,
          [`& .${selectClasses.select}`]: {
            fontSize: '1rem',
            p: 0,
            pointerEvents: 'auto',
          },
        }}
      >
        {options}
      </Select>
    </FormControl>
  )
}
