import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'

import { useApiClient } from '../../providers'
import { getFuncByEntity } from './own/lib.map.ts'
import {
  ApiClientSearchByStringFuncType,
  EntityType,
  FuncSort,
} from './own/lib.resources.ts'
import { hasPropertyAndNotNull } from './own/lib.utils.ts'
import { ApiClientSearchByStringFilter } from './own/types.ts'

export const useSearchManyByString = <T>(
  entity: EntityType,
  filter: ApiClientSearchByStringFilter,
  extraOptions?: Partial<UseQueryOptions>,
  enabled = true
): UseQueryResult<T[]> => {
  const { apiClient } = useApiClient()
  const func = getFuncByEntity(
    FuncSort.search,
    entity
  ) as ApiClientSearchByStringFuncType
  const key = filter.key !== '' ? filter.key : undefined

  const queryResult = useQuery({
    queryKey: [entity, FuncSort.search, key],
    enabled:
      enabled &&
      !!apiClient &&
      hasPropertyAndNotNull(apiClient, func) &&
      Boolean(key),
    queryFn: () => apiClient[func](key),
    select: (result: any) => result?.cleanedResponse ?? [],
    ...extraOptions,
  })

  return queryResult as UseQueryResult<T[]>
}
