import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'

import { getFuncByEntity } from 'src/common/lib'
import { useApiClient } from 'src/common/providers'

import {
  ApiClientSearchByValueFuncType,
  EntityType,
  FuncSort,
} from './own/lib.resources'
import { hasPropertyAndNotNull } from './own/lib.utils'
import { ApiClientSearchByValueFilter } from './own/types'

export const useSearchManyByValue = <T>(
  filter: ApiClientSearchByValueFilter,
  entity: EntityType,
  enabled = true,
  extraOptions?: Partial<UseQueryOptions>
): UseQueryResult<T> => {
  const { apiClient } = useApiClient()
  const func = getFuncByEntity(
    FuncSort.search,
    entity
  ) as ApiClientSearchByValueFuncType
  const key =
    (typeof filter.key === 'string' ? Number(filter.key) : filter.key) ??
    undefined

  const queryResult = useQuery({
    queryKey: [entity, FuncSort.search, key],
    enabled:
      enabled &&
      !!apiClient &&
      hasPropertyAndNotNull(apiClient, func) &&
      !(key === undefined || key === null || isNaN(key)),
    select: (result: any) => result?.cleanedResponse ?? [],
    queryFn: () => apiClient[func](key) as Promise<T>,
    ...extraOptions,
  })
  return queryResult as UseQueryResult<T>
}
