import { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Chip } from '@mui/material'

interface IProps {
  value: boolean
  inverseColors?: boolean
}

const BooleanTag: FC<IProps> = (props: IProps): ReactElement => {
  const { value, inverseColors } = props
  const { t } = useTranslation()

  return (
    <Box data-testid='tag'>
      <Chip
        color={
          (value && inverseColors) || (!value && !inverseColors)
            ? 'error'
            : 'success'
        }
        label={value ? t('common.yes') : t('common.no')}
        sx={{
          width: '55px',
        }}
      />
    </Box>
  )
}

export default BooleanTag
