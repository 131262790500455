import {
  type IPurchaserLimitsFilterInput,
  type IUserAccountDayLimitView,
  utcNever,
} from 'src/common'

export const defaultDayLimit = {
  userAccountDayLimitId: 0,
  userAccountDayLimitAmount: 0,
  endDateTime: utcNever(),
  userAccountName: '',
}

export const defaultFilter: IPurchaserLimitsFilterInput = {
  userAccountName: undefined,
  customerAdministrationNumber: undefined,
  customerName: undefined,
}

export const ROOT_PATH = 'purchaser-limits'

export const dummyRows: IUserAccountDayLimitView[] = [
  // fill dummy values
  {
    userAccountDayLimitId: 1,
    userAccountId: 1,
    userAccountName: 'dummy',
    customerAdministrationNumber: 1,
    customerName: 'dummy',
    userAccountDayLimitAmount: 20000,
    startDateTime: new Date(),
    endDateTime: new Date(),
    isUserAccountBlocked: true,
    blockedDate: new Date(),
    creationDateTime: new Date(),
    creationUser: 'dummy',
    mutationDateTime: new Date(),
    mutationUser: 'dummy',
  },
]
