import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'

import { useStore } from 'zustand'

import { Entity, useDeleteEntity } from 'src/common/hooks'
import SnackbarUtils from 'src/common/utils/SnackbarUtils'

import { useCardRowsStore, usePurchaserStore } from '../stores'

type DeletePurchaserCardDialogProps = {
  idToBeDeleted: number
  open: boolean
  onClose: () => void
  onCancel: () => void
}

export const DeletePurchaserCardDialog: FC<DeletePurchaserCardDialogProps> = ({
  idToBeDeleted,
  open,
  onClose: close,
  onCancel: cancel,
}) => {
  const { t } = useTranslation()

  const deleteEntity = useDeleteEntity(Entity.IInkoperPlaat)
  const {
    getCardsByPlateNumber,
    removePurchaserCard,
    setMustRefetchPurchaserCards,
  } = usePurchaserStore()
  const removeCardRow = useStore(useCardRowsStore, state => state.removeCardRow)

  const handleSubmit = useCallback(async (): Promise<void> => {
    if (!idToBeDeleted || deleteEntity.isLoading) {
      return Promise.resolve()
    }

    try {
      removeCardRow(idToBeDeleted)
      const cards = getCardsByPlateNumber(idToBeDeleted)
      const promises: Promise<void>[] = []
      cards.forEach(plaat => {
        removePurchaserCard(plaat.inkoperPlaatID)
        const promise = deleteEntity.mutateAsync(plaat.inkoperPlaatID)
        promises.push(promise)
      })
      await Promise.all(promises)
      setMustRefetchPurchaserCards(true)
      SnackbarUtils.success(t('common.statusCodes.success'))
      close()
    } catch (error) {
      SnackbarUtils.error(`${error}`)
    }
  }, [
    close,
    deleteEntity,
    getCardsByPlateNumber,
    idToBeDeleted,
    removeCardRow,
    removePurchaserCard,
    setMustRefetchPurchaserCards,
    t,
  ])

  return (
    <Dialog open={open} onClose={cancel}>
      <DialogTitle>
        {t('purchaser.purchaserCard.deleteDialog.title')}
      </DialogTitle>
      <DialogContent>
        {t('purchaser.purchaserCard.deleteDialog.description', {
          code: idToBeDeleted,
        })}
      </DialogContent>
      <DialogActions>
        <Button variant='text' onClick={cancel}>
          {t('common.no')}
        </Button>
        <Button onClick={handleSubmit} disabled={deleteEntity.isLoading}>
          {t('common.yes')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
