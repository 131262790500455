import { Suspense, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Button, Chip } from '@mui/material'
import Grid from '@mui/material/Grid2'

import { formatDate } from 'src/common'
import { BooleanTag, FullScreenDialog, Spinner } from 'src/common/components'
import { Entity, useFetchOne } from 'src/common/hooks'
import { type IBuyersCardView } from 'src/common/services/client'
import { useUserStore } from 'src/common/stores/UserStore'

import BuyersCardsDialogDelete from '../BuyersCardsDialogDelete'
import { StyledGridButtonContainer } from './BuyersCardsDetails.styles'
import DetailCard from './DetailCard'

type BuyersCardsDetailsProps = {
  open: boolean
  cardId: string
  onClose: () => void
  refresh: () => void
}

export default function BuyersCardsDetails({
  cardId,
  open,
  onClose,
  refresh,
}: Readonly<BuyersCardsDetailsProps>): JSX.Element {
  const { t } = useTranslation()
  const { user } = useUserStore()
  const [deleteOpen, setDeleteOpen] = useState(false)

  const {
    data: card,
    isLoading,
    isError,
  } = useFetchOne<IBuyersCardView>(
    {
      key: Number(cardId),
    },
    Entity.BuyersCardView,
    Boolean(cardId) && open
  )

  const closeOnDelete = () => {
    setDeleteOpen(false)
    onClose()
  }

  const formatDateWithChip = (
    value: any,
    dateFormat: string,
    detailString?: string
  ) => {
    if (value && value != null) {
      if (detailString) {
        return (
          <>
            {formatDate(new Date(value), dateFormat)}{' '}
            <Chip label={detailString} color='info' />
          </>
        )
      }
      return formatDate(new Date(value), dateFormat)
    }
    return '-'
  }

  return (
    <>
      <FullScreenDialog
        title={t('common.titles.buyersCardsDetails')}
        open={open}
        handleClose={onClose}
      >
        <Suspense fallback={<Spinner isError={isError} />}>
          <Grid
            container
            spacing={2}
            sx={{
              width: '100%',
              marginLeft: '0px',
            }}
          >
            <DetailCard
              isLoading={isLoading}
              isError={isError}
              title={t('common.buyersCard')}
              details={[
                {
                  name: t('buyersCards.plateNumber'),
                  value: card?.buyersCardId,
                },
                {
                  name: t('buyersCards.createDate'),
                  value: card
                    ? formatDateWithChip(
                        card?.creationDateTime,
                        t('common.dateFormat'),
                        card?.creationUser
                      )
                    : '-',
                },
                {
                  name: t('buyersCards.buyersCardsDetails.mutationDate'),
                  value: card
                    ? formatDateWithChip(
                        card?.mutationDateTime,
                        t('common.dateFormat'),
                        card?.mutationUser
                      )
                    : '-',
                },
                {
                  name: t('buyersCards.startDate'),
                  value: card
                    ? formatDateWithChip(
                        card?.startDate,
                        t('common.dateFormatShort')
                      )
                    : '-',
                },
                {
                  name: t('buyersCards.endDate'),
                  value: card
                    ? formatDateWithChip(
                        new Date(card?.endDate),
                        t('common.dateFormatShort')
                      )
                    : '-',
                },
              ]}
            />
            <DetailCard
              isLoading={isLoading}
              isError={isError}
              title={t('buyersCards.buyersCardsDetails.subTitleBlock')}
              details={[
                {
                  name: t('buyersCards.blockDate'),
                  value: card
                    ? formatDateWithChip(
                        card?.blockedDateTime,
                        t('common.dateFormat')
                      )
                    : '-',
                },
                {
                  name: t('buyersCards.releaseDate'),
                  value: card
                    ? formatDateWithChip(
                        card?.releasedDateTime,
                        t('common.dateFormat')
                      )
                    : '-',
                },
                {
                  name: t('common.remark'),
                  value: card?.remark ?? '-',
                },
                {
                  name: t('buyersCards.expired'),
                  value: <BooleanTag value={card?.isVervallen} inverseColors />,
                },
                {
                  name: t('buyersCards.blocked'),
                  value: (
                    <BooleanTag value={card?.blockedindication} inverseColors />
                  ),
                },
              ]}
            />

            <DetailCard
              isLoading={isLoading}
              isError={isError}
              title={t('buyersCards.auctionLocation')}
              details={[
                {
                  name: 'Aalsmeer',
                  value: <BooleanTag value={card?.aalsmeer} />,
                },
                {
                  name: 'Naaldwijk',
                  value: <BooleanTag value={card?.naaldwijk} />,
                },
                {
                  name: 'Rijnsburg',
                  value: <BooleanTag value={card?.rijnsburg} />,
                },
                {
                  name: 'Eelde',
                  value: <BooleanTag value={card?.eelde} />,
                },
              ]}
            />

            <DetailCard
              isLoading={isLoading}
              isError={isError}
              title={t('buyersCards.buyersCardsDetails.subTitleCustomer')}
              details={[
                {
                  name: t('buyersCards.customerNumber'),
                  value: card?.customerRfhRelationId,
                },
                {
                  name: t('buyersCards.customerName'),
                  value: card?.customrRfhRelationName,
                },
              ]}
            />
            <StyledGridButtonContainer size={{ xs: 12 }}>
              {(user.isAccountManagement || user.isApplicationManager) &&
              !card?.isVervallen ? (
                <>
                  <Button
                    variant='outlined'
                    component={Link}
                    to={`/dashboard/buyerscards/${card?.buyersCardId}/edit`}
                    disabled={!card || card?.endDate < new Date()}
                  >
                    {t('common.edit')}
                  </Button>
                  <Button
                    variant='outlined'
                    onClick={() => setDeleteOpen(true)}
                    disabled={!card || card?.endDate < new Date()}
                  >
                    {t('common.delete')}
                  </Button>
                </>
              ) : null}

              <Button variant='contained' autoFocus onClick={onClose}>
                {t('common.back')}
              </Button>
            </StyledGridButtonContainer>
          </Grid>
        </Suspense>
      </FullScreenDialog>
      <BuyersCardsDialogDelete
        buyersCardToBeDeleted={card}
        open={deleteOpen}
        onCancel={() => setDeleteOpen(false)}
        onClose={closeOnDelete}
        refresh={refresh}
      />
    </>
  )
}
