export const FormMode = {
  add: 'ADD',
  edit: 'EDIT',
  view: 'VIEW',
} as const

export type FormModeType = (typeof FormMode)[keyof typeof FormMode]

export const Direction = {
  asc: 'ASC',
  desc: 'DESC',
} as const

export type DirectionType = (typeof Direction)[keyof typeof Direction]

export const PageSize = {
  sm: 10,
  md: 25,
  lg: 50,
  xl: 100,
  xxl: 250,
  xxxl: 500,
  xxxxl: 1000,
} as const

export type PageSizeType = (typeof PageSize)[keyof typeof PageSize]

export const UpsertMode = {
  add: 'ADD',
  edit: 'EDIT',
  void: 'VOID',
} as const

export type UpsertModeType = (typeof UpsertMode)[keyof typeof UpsertMode]

// see https://www.htmlcodes.ws/color/html-color-tester.cfm
export const LogColor = {
  aqua: 'Aqua',
  aquamarine: 'Aquamarine',
  blue: 'Blue',
  coral: 'Coral',
  darkKhaki: 'DarkKhaki',
  fuchsia: 'Fuchsia',
  gold: 'Gold',
  hotPink: 'HotPink',
  khaki: 'Khaki',
  indianRed: 'IndianRed',
  indigo: 'Indigo',
  lavender: 'Lavender',
  lightPink: 'LightPink',
  lime: 'Lime',
  limeGreen: 'LimeGreen',
  maroon: 'Maroon',
  mediumPurple: 'MediumPurple',
  mediumSpringGreen: 'MediumSpringGreen',
  mediumTurquoise: 'MediumTurquoise',
  navy: 'Navy',
  peachPuff: 'PeachPuff',
  purple: 'Purple',
  salmon: 'Salmon',
  sienna: 'Sienna',
  steelBlue: 'SteelBlue',
  tomato: 'Tomato',
  yellow: 'Yellow',
} as const

export type LogColorType = (typeof LogColor)[keyof typeof LogColor]
