import { FC } from 'react'

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const FlagNL: FC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox='0 0 16 12' aria-label={'dutch national flag'}>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-65.000000, -4299.000000)'>
        <g transform='translate(61.000000, 3171.000000)'>
          <g transform='translate(0.000000, 722.000000)'>
            <g transform='translate(4.000000, 406.000000)'>
              <rect fill='#DA2B35' x='0' y='0' width='16' height='4' />
              <rect fill='#FFFFFF' x='0' y='4' width='16' height='4' />
              <rect fill='#003790' x='0' y='8' width='16' height='4' />
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
)

export default FlagNL
