/* eslint-disable */
import { OptionsObject, SnackbarKey } from 'notistack'

import { ReactElement } from 'react'

export type MessageType = string | ReactElement

class SnackbarUtils {
  private snackBar = {
    enqueueSnackbar: (msg: MessageType, options?: OptionsObject) => {
      /* empty object */
    },
    closeSnackbar: (key?: SnackbarKey) => {
      /* empty object */
    },
  }

  success(msg: MessageType, options: OptionsObject = {}) {
    return this.toast(msg, { ...options, variant: 'success' })
  }

  warning(msg: MessageType, options: OptionsObject = {}) {
    return this.toast(msg, { ...options, variant: 'warning' })
  }

  info(msg: MessageType, options: OptionsObject = {}) {
    return this.toast(msg, { ...options, variant: 'info' })
  }

  error(msg: MessageType, options: OptionsObject = {}) {
    return this.toast(msg, { ...options, variant: 'error' })
  }

  toast(msg: MessageType, options: OptionsObject = {}) {
    const finalOptions: OptionsObject = {
      variant: 'default',
      ...options,
    }

    // // If the message is a string, display it as a regular toast
    if (typeof msg === 'string') {
      return this.snackBar.enqueueSnackbar(msg, { ...finalOptions })
    }

    // If the message is a React node, display it as a multi-row notification
    return this.snackBar.enqueueSnackbar(<>{msg}</>, {
      ...finalOptions,
    })
  }

  closeSnackbar(key: SnackbarKey) {
    this.snackBar.closeSnackbar(key)
  }

  setSnackBar(
    enqueueSnackbar: (
      msg: MessageType,
      options?: OptionsObject
    ) => {
      /* empty object */
    },
    closeSnackbar: (key?: SnackbarKey) => void
  ) {
    this.snackBar.enqueueSnackbar = enqueueSnackbar
    this.snackBar.closeSnackbar = closeSnackbar
  }
}

const snackbarUtils = new SnackbarUtils()

export default snackbarUtils
