import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'

import { getFuncByEntity } from 'src/common/lib'
import { useApiClient } from 'src/common/providers'
import SnackbarUtils from 'src/common/utils/SnackbarUtils'

import {
  ApiClientDeleteFuncType,
  EntityType,
  FuncSort,
} from './own/lib.resources'

export const useDeleteEntity = (
  entity: EntityType
): UseMutationResult<void, unknown, number, unknown> => {
  const { apiClient } = useApiClient()
  const func = getFuncByEntity(
    FuncSort.delete,
    entity
  ) as ApiClientDeleteFuncType
  const queryClient = useQueryClient()

  const result = useMutation({
    mutationKey: [entity, FuncSort.delete],
    mutationFn: (key: number) => apiClient[func](key),
    onSettled: () => {
      queryClient.invalidateQueries()
    },
  })

  if (result.isError) {
    SnackbarUtils.error(`${result.error}`)
  }

  return result
}
