import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import type { GridCellParams, GridColDef } from '@mui/x-data-grid-premium'
import theme from '@rfh-core/theme'

import {
  CustomDataGrid,
  getFileNameFromPageTitle,
  getToolbarProps,
  type IKlantLimiet,
  noOp,
} from 'src/common'

import { getClientLimitsColumns } from '../lib'

/* eslint-disable @typescript-eslint/no-unused-vars */
type ClientLimitsGridProps = {
  isLoading: boolean
  refresh?: () => void
  rows: IKlantLimiet[]
  totalCount: number
}

export default function ClientLimitsGrid({
  isLoading,
  refresh = noOp,
  rows,
  totalCount,
}: Readonly<ClientLimitsGridProps>): JSX.Element {
  const { i18n, t } = useTranslation()
  const overLimitStyle = {
    '& .over-limit': {
      backgroundColor: theme.rfhColors.alertRed,
      color: theme.rfhColors.white,
      fontWeight: 'bold',
    },
  } as const

  const fileName = useMemo(
    () => getFileNameFromPageTitle(t('common.titles.clientLimitsReadOnly')),
    [t]
  )
  const getRowId = useCallback(
    (row: IKlantLimiet): number => row.klantNummer,
    []
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columns = useMemo((): GridColDef[] => {
    const cols = getClientLimitsColumns()
    cols
      .filter(col => col.field === 'limiet')
      .forEach(col => {
        col.cellClassName = (params: GridCellParams<IKlantLimiet>) => {
          const isOverLimit =
            params.row?.omzet >= params.row?.limiet || params.row?.limiet === 1
          return isOverLimit ? 'over-limit' : ''
        }
      })
    return cols
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t])

  return (
    <CustomDataGrid
      columns={columns}
      getRowId={getRowId}
      gridName={'clientLimits'}
      gridProps={{
        export: { title: t('client.dayLimits') },
        loading: isLoading,
        rowCount: totalCount,
      }}
      hideHorizontalScrollbar
      language={i18n.language}
      rows={rows}
      toolbarProps={getToolbarProps(fileName)}
      sx={overLimitStyle}
    />
  )
}
