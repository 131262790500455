import { MouseEventHandler, type ReactNode } from 'react'
import { Link } from 'react-router-dom'

import {
  Box,
  Card,
  CardContent,
  CardMedia,
  styled,
  svgIconClasses,
  Typography,
} from '@mui/material'

type MenuCardProps = {
  icon?: ReactNode
  title: string
  subheader?: string
  bodyText?: string
  onClick?: MouseEventHandler
  to?: string
}

const StyledCard = styled(Card)(({ theme }) => ({
  height: '125px !important',
  justifyContent: 'space-between',
  textDecoration: 'none',
  boxShadow: theme.shadows[7],
  transition: 'box-shadow 500ms ease',
  '&:hover': {
    boxShadow: theme.shadows[0],
  },
})) as typeof Card

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [`& .${svgIconClasses.root}`]: {
    fontSize: '2.5em',
    marginLeft: '1rem',
    color: theme.palette.common.white,
  },
}))

export default function MenuCard({
  icon: Icon,
  title,
  subheader,
  bodyText,
  onClick,
  to = '',
}: Readonly<MenuCardProps>): JSX.Element {
  return (
    <StyledCard
      component={Link}
      to={to}
      className='CardSecondary'
      data-testid='card'
      onClick={onClick}
    >
      <CardContent>
        <Box>
          <Typography className='book' variant='h4'>
            {title}
          </Typography>
          <Typography variant='subtitle2'>{subheader}</Typography>
          <Typography variant='body1'>{bodyText}</Typography>
        </Box>
      </CardContent>
      <StyledCardMedia>{Icon}</StyledCardMedia>
    </StyledCard>
  )
}
