import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

import { type IKlantLimiet } from 'src/common'

type DayLimit = Partial<IKlantLimiet>

type StoreState = {
  isLoadingClientLimits: boolean
  mustRefreshClientLimitsData: boolean
}

type Actions = {
  /* store */
  resetClientLimitsStoreState: () => void
  setIsLoadingClientLimits: (input: boolean) => void
  setMustRefreshClientLimitsData: (input: boolean) => void
  /* computables */
}

const storeName = 'ClientLimitsStore'

const initialStoreState: StoreState = {
  isLoadingClientLimits: false,
  mustRefreshClientLimitsData: true,
}
type StoreType = StoreState & Actions

export const useClientLimitsStore = create<StoreType>()(
  devtools(
    persist(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (set, get: () => StoreType) => ({
        ...initialStoreState,
        resetClientLimitsStoreState: () => {
          set(
            (): StoreState => initialStoreState,
            false, // replace or not replace - that is the question!
            'resetClientLimitsState'
          )
        },
        setIsLoadingClientLimits: (input: boolean) => {
          set(() => ({ isLoadingClientLimits: input }), false, 'setIsLoadingClientLimits')
        },
        setMustRefreshClientLimitsData: (input: boolean) => {
          set(
            () => ({ mustRefreshClientLimitsData: input }),
            false,
            'setMustRefreshClientLimitsData'
          )
        },
        /* computables */
      }),
      {
        name: 'client-limits-storage',
        storage: createJSONStorage(() => sessionStorage),
      }
    ),
    { name: storeName }
  )
)
