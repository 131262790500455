import { LogColorType } from 'src/common/lib'
import { getTimeStampNow } from 'src/common/utils/DateFunctions'

type UseColorLogReturn = (
  color: LogColorType,
  where: string,
  which: string,
  showWhat?: any
) => void

export const useColorLog = (): UseColorLogReturn => {
  const colorLog = (
    color: LogColorType,
    where: string,
    which: string,
    showWhat?: any
  ): void => {
    if (process.env.NODE_ENV === 'production') return

    const timeStamp = getTimeStampNow()
    const styleWhere = 'font-size: 12px; font-weight: bold'
    const styleRest = 'color:' + color

    console.log(`%c${where}\n`, styleWhere)
    console.log(`   ${timeStamp}\n%c   ${which}`, styleRest)

    if (!showWhat) return

    const whats = Array.isArray(showWhat) ? showWhat : [showWhat]
    whats.forEach((what: any) => {
      if (typeof what === 'string') {
        console.log(`%c   => ${what}`, styleRest)
      } else if (what) {
        console.dir('   =>', what)
      }
    })
  }

  return colorLog
}
