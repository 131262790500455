import { FC } from 'react'

import { TableCell, TableRow } from '@mui/material'

import { IKoper } from 'src/common/services/client'

type BuyersCardsDialogAvailableBuyersItemProps = {
  onClick: (evt: any) => void
  buyer: IKoper
}

const BuyersCardsDialogAvailableBuyersItem: FC<
  BuyersCardsDialogAvailableBuyersItemProps
> = ({ buyer, onClick }) => {
  const submit = () => {
    onClick({
      target: { name: 'klantnummer', value: buyer.kopernummer },
    })
  }

  return (
    <TableRow
      style={{
        cursor: 'pointer',
      }}
      key={buyer.kopernummer}
      hover={true}
    >
      <TableCell onClick={submit}>{buyer.kopernummer}</TableCell>
      <TableCell onClick={submit}>{buyer.kopernaam}</TableCell>
    </TableRow>
  )
}

export default BuyersCardsDialogAvailableBuyersItem
