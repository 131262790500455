import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

import { type IUserAccountDayLimit } from 'src/common'

import { defaultDayLimit } from '../lib/lib.constants'

type DayLimit = Partial<IUserAccountDayLimit>

type StoreState = {
  isLoadingPurchaserLimits: boolean
  mustRefreshPurchaserLimitsData: boolean
  dayLimit: DayLimit
  oldDayLimit: DayLimit
}

type Actions = {
  /* store */
  resetPurchaserLimitsState: () => void
  setIsLoadingPurchaserLimits: (input: boolean) => void
  setMustRefreshPurchaserLimitsData: (input: boolean) => void
  setDayLimit: (dl: DayLimit) => void
  updateDayLimit: (dl: DayLimit) => void
  /* computables */
  isPurchaserLimitAmountChanged: () => boolean
  isPurchaserLimitDirty: () => boolean
}

const storeName = 'PurchaserLimitsStore'

const initialStoreState: StoreState = {
  isLoadingPurchaserLimits: false,
  mustRefreshPurchaserLimitsData: true,
  dayLimit: { ...defaultDayLimit },
  oldDayLimit: { ...defaultDayLimit },
}
type StoreType = StoreState & Actions

export const usePurchaserLimitsStore = create<StoreType>()(
  devtools(
    persist(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (set, get: () => StoreType) => ({
        ...initialStoreState,
        resetPurchaserLimitsState: () => {
          set(
            (): StoreState => initialStoreState,
            false, // replace or not replace - that is the question!
            'resetPurchaserLimitsState'
          )
        },
        setDayLimit: (dl: DayLimit) =>
          set(
            () => ({
              dayLimit: { ...dl },
              oldDayLimit: { ...dl },
            }),
            false,
            'setDayLimit'
          ),
        updateDayLimit: (udl: DayLimit) =>
          set(
            (state: StoreState) => ({
              dayLimit: { ...state.dayLimit, ...udl },
            }),
            false,
            'updateDayLimit'
          ),
        setIsLoadingPurchaserLimits: (input: boolean) => {
          set(
            () => ({ isLoadingPurchaserLimits: input }),
            false,
            'setIsLoadingPurchaserLimits'
          )
        },
        setMustRefreshPurchaserLimitsData: (input: boolean) => {
          set(
            () => ({ mustRefreshPurchaserLimitsData: input }),
            false,
            'setMustRefreshPurchaserLimitsData'
          )
        },
        /* computables */
        isPurchaserLimitAmountChanged: () => {
          const amount = get().dayLimit?.userAccountDayLimitAmount
          const oldAmount = get().oldDayLimit?.userAccountDayLimitAmount
          return String(amount) !== String(oldAmount)
        },
        isPurchaserLimitDirty: () => {
          const amount = get().dayLimit?.userAccountDayLimitAmount
          const oldAmount = get().oldDayLimit?.userAccountDayLimitAmount
          const dateTime = get().dayLimit?.endDateTime
          const oldDateTime = get().oldDayLimit?.endDateTime
          return (
            String(amount) !== String(oldAmount) ||
            String(dateTime) !== String(oldDateTime)
          )
        },
      }),
      {
        name: 'purchaser-limits-storage',
        storage: createJSONStorage(() => sessionStorage),
      }
    ),
    { name: storeName }
  )
)
