import { ComponentProps } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, SxProps } from '@mui/material'

import { ThemeConfig } from 'src/common/config'
import { ELEMENT_HEIGHT } from 'src/common/constants'

type ActionButtonProps = ComponentProps<typeof Button> & {
  buttonSx?: SxProps
  disabled?: boolean
  height?: number
  marginRight?: number
  width?: number
  children?: React.ReactNode
}

export default function ActionButton({
  buttonSx,
  disabled = false,
  height = ELEMENT_HEIGHT,
  marginRight = 0,
  onClick,
  type = 'button',
  variant = 'contained',
  width = ThemeConfig.spacing.xl * 16,
  children,
  ...rest
}: Readonly<ActionButtonProps>): JSX.Element {
  const { t } = useTranslation()

  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      type={type}
      variant={variant}
      sx={{
        pointerEvents: 'auto',
        height,
        width,
        marginRight,
        ...buttonSx,
      }}
      {...rest}
    >
      {children ?? t('common.save')}
    </Button>
  )
}
