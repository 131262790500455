import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'

import { getFuncByEntity } from 'src/common/lib'
import { useApiClient } from 'src/common/providers'
import SnackbarUtils from 'src/common/utils/SnackbarUtils'

import {
  ApiClientUpdateFuncType,
  EntityType,
  FuncSort,
} from './own/lib.resources'

export const useUpdateEntity = <T>(
  entity: EntityType
): UseMutationResult<unknown, unknown, T, unknown> => {
  const { apiClient } = useApiClient()
  const func = getFuncByEntity(
    FuncSort.update,
    entity
  ) as ApiClientUpdateFuncType
  const queryClient = useQueryClient()
  const result = useMutation<unknown, unknown, T, unknown>({
    mutationKey: [entity, FuncSort.update],
    mutationFn: (body: T) => apiClient[func](body as any),
    onSettled: () => {
      queryClient.invalidateQueries()
    },
  })

  if (result.isError) {
    SnackbarUtils.error(`${result.error}`)
  }

  return result
}
