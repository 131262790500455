import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Box, Button, Container } from '@mui/material'
import { EmptyState, EmptyStateIcon } from '@rfh-core/components'

type GenericErrorProps = {
  error: Error
}

const GenericError: FC<GenericErrorProps> = ({ error }) => {
  const { t } = useTranslation()

  return (
    <Container
      maxWidth='lg'
      sx={{
        maxWidth: '1280px',
        height: 'calc(100vh - 72px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: '770px',
        }}
      >
        <EmptyState
          header={t('common.genericError')}
          subheader={<p>{`${error}`}</p>}
          graphic={<EmptyStateIcon />}
          bottomComponent={
            <Button variant='contained' component={Link} to='/dashboard'>
              {t('common.retry')}
            </Button>
          }
        />
      </Box>
    </Container>
  )
}

export default GenericError
