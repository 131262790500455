import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

import {
  ApiClientSearchByStringFilter,
  Entity,
  type IFloridayUser,
  useSearchManyByString,
} from 'src/common'
import Spinner from 'src/common/components/Spinner'

import { usePurchaserStore } from '../../stores'
import { FloridayUserItem } from '../FloridayUserSearch/FloridayUserItem.tsx'

type FloridayBuyersSearchDialogProps = {
  open: boolean
  setOpen: (input: boolean) => void
  onChange: (evt: IFloridayUser) => void
}

export const FloridayBuyersSearchDialog = ({
  setOpen,
  onChange,
  open,
}: Readonly<FloridayBuyersSearchDialogProps>): JSX.Element => {
  const { t } = useTranslation()
  const { purchaser } = usePurchaserStore()
  const [filter, setFilter] = useState<ApiClientSearchByStringFilter>({
    key: purchaser?.inkoperIdentificatie,
  })

  const { data: users, isLoading } = useSearchManyByString<IFloridayUser>(
    Entity.FloridayUser,
    filter,
    null,
    open
  )

  const handleSelectedItem = (evt: IFloridayUser) => {
    onChange(evt)
    setOpen(false)
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (purchaser?.inkoperIdentificatie) {
      setFilter({
        key: purchaser?.inkoperIdentificatie,
      })
    }
  }, [purchaser?.inkoperIdentificatie])

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogContent>
        <Typography
          variant={'h4'}
          sx={{ mb: '1rem', textTransform: 'uppercase' }}
        >
          {t('purchaser.searchFloridayUsers')}
        </Typography>
        <Table style={{ minHeight: '100px' }}>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  textAlign: 'left',
                  verticalAlign: 'top',
                }}
              >
                {t('purchaser.floridayUserName')}
              </TableCell>
              <TableCell
                style={{
                  textAlign: 'left',
                  verticalAlign: 'top',
                }}
              >
                {t('purchaser.buyerName')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={2}>
                  <Spinner />
                </TableCell>
              </TableRow>
            )}
            {!isLoading && !users?.length && (
              <TableRow>
                <TableCell colSpan={2}>{t('common.noResults')}</TableCell>
              </TableRow>
            )}
            {!isLoading &&
              users?.length > 0 &&
              users.map(value => (
                <FloridayUserItem
                  key={value.technicalId}
                  user={value}
                  onClick={handleSelectedItem}
                />
              ))}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  )
}
