import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'

import { Entity, useDeleteEntity } from 'src/common/hooks'
import { IBuyersCardView } from 'src/common/services/client'
import SnackbarUtils from 'src/common/utils/SnackbarUtils'

type BuyersCardsDialogDeleteProps = {
  buyersCardToBeDeleted: IBuyersCardView
  open: boolean
  onClose: () => any
  onCancel: () => any
  refresh: () => any
}

const BuyersCardsDialogDelete: FC<BuyersCardsDialogDeleteProps> = ({
  buyersCardToBeDeleted,
  open,
  onClose,
  onCancel,
  refresh,
}) => {
  const { t } = useTranslation()
  const deleteEntity = useDeleteEntity(Entity.BuyersCard)

  /*
   * Methods
   */
  const onSubmit = async () => {
    if (deleteEntity.isLoading) return Promise.resolve()
    try {
      onClose()
      await deleteEntity.mutateAsync(buyersCardToBeDeleted.buyersCardId)
      SnackbarUtils.success(
        t('buyersCards.deleteSuccesMessage', {
          code: buyersCardToBeDeleted.buyersCardId,
        })
      )
      refresh()
    } catch (error) {
      SnackbarUtils.error(`${error}`)
    }
  }

  /*
   * Render
   */
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{t('deleteBuyerscardDialog.title')}</DialogTitle>
      <DialogContent>
        {t('deleteBuyerscardDialog.description', {
          code: buyersCardToBeDeleted ? buyersCardToBeDeleted.buyersCardId : 0,
        })}
      </DialogContent>
      <DialogActions>
        <Button variant='text' onClick={onCancel}>
          {t('common.no')}
        </Button>
        <Button onClick={onSubmit} disabled={deleteEntity.isLoading}>
          {t('common.yes')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default BuyersCardsDialogDelete
