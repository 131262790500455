import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid-premium'

import { DEFAULT_PAGE_SIZE } from '../constants'

/**
 * Formats MUI DataGrid GridSortModel to an correctly formatted string for OData to understand
 * @param sortModel The GridSortModel that needs to be converted
 */
export const getOrderBy = (sortModel: GridSortModel): string => {
  if (!sortModel || sortModel.length === 0) {
    return undefined
  }

  let orderBy = ''

  // Add for each item the string 'fieldName asc|desc' if it is not the last one add an ',' for the next row
  sortModel?.forEach((item, index) => {
    orderBy += `${item.field} ${item.sort}${
      sortModel.length - 1 !== index ? ', ' : ''
    }`
  })

  return orderBy
}

export const getTop = (paginationModel: GridPaginationModel): number =>
  paginationModel?.pageSize ?? DEFAULT_PAGE_SIZE

export const getSkip = (paginationModel: GridPaginationModel): number =>
  paginationModel?.page && paginationModel?.pageSize
    ? paginationModel?.page * paginationModel?.pageSize
    : 0
