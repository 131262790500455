import { FC, type ReactNode } from 'react'

import { Box } from '@mui/material'

import { BUTTONS_GAP } from '../constants'

type ButtonsBlockProps = {
  gap?: number
  mr?: number

  children: ReactNode
}

/**
 * Renders a stack of buttons (with absolute positioning).
 *
 * @component
 *
 * @param {Object} props - The component props.
 * @param {marginLeft} [props.marginLeft=0] - Margin left.
 * @param {marginRight} [props.marginRight=] - Margin right.
 * @param {marginBottom} [props.marginBottom=] - Margin bottom.
 * @param {marginTop} [props.marginTop=] - Margin top.
 * @param {'left' | 'right' | 'center'} [props.placeButtons='right'] - Position of the buttons block.
 * @param {ReactNode} props.children - The child elements to render inside the button stack.
 *
 * @returns {JSX.Element} - The rendered component.
 */
const ButtonsBlock: FC<ButtonsBlockProps> = ({
  gap = BUTTONS_GAP,
  mr = 0,
  children,
}) => (
  <Box
    display={'flex'}
    justifyContent={'flex-end'}
    gap={gap}
    sx={{
      position: 'absolute',
      mr,
      zIndex: 10,
    }}
  >
    {children}
  </Box>
)

export default ButtonsBlock
