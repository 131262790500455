import { FC } from 'react'

import { Box, Skeleton, Stack } from '@mui/material'
import Grid from '@mui/material/Grid2'

export const PurchaserCardsSkeleton: FC = () => {
  const dummyArray = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }]
  return (
    <Grid
      container
      spacing={2}
      sx={{
        width: '100%',
        marginTop: 6,
      }}
    >
      <Grid
        size={{ xs: 12 }}
        sx={{
          padding: '0 !important',
          marginLeft: 2,
        }}
      >
        <Box mb={2}>
          <Skeleton variant={'text'} sx={{ fontSize: '3em', width: '20%' }} />
        </Box>
        <Stack spacing={1}>
          {dummyArray.map(el => (
            <Box display={'flex'} gap={2} key={el.id}>
              <Skeleton variant='rectangular' width={'100%'} height={40} />
            </Box>
          ))}
        </Stack>
      </Grid>
    </Grid>
  )
}
