import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'

type PropertyProps = {
  name: string
  value: string | JSX.Element
}

export default function Property({
  name,
  value,
}: Readonly<PropertyProps>): JSX.Element {
  const fontSize = '1.1rem'
  const getIdFromString = (svalue: string): string =>
    svalue
      .toLowerCase()
      // replace spaces with a hyphen
      .replace(/\s+/g, '-')
      // remove any other non-alphanumeric characters
      .replace(/[^a-z0-9-]/g, '')

  return (
    <Grid
      container
      data-testid={getIdFromString(name)}
      justifyContent={'flex-start'}
      alignItems={'center'}
      mb={2}
    >
      <Typography
        variant={'h4'}
        minWidth={220}
        sx={{
          fontSize: `calc(${fontSize}*1.1}) !important`,
          fontFamily: 'Halyard-display',
        }}
      >
        {name}
      </Typography>
      <Typography
        variant={'subtitle1'}
        sx={{ fontSize, fontFamily: 'Halyard-display' }}
      >
        {value}
      </Typography>
    </Grid>
  )
}
