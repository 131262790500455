import { deDE, GridLocaleText, nlNL } from '@mui/x-data-grid-premium'

export const getLocale = (
  language: string
): Partial<GridLocaleText> | undefined => {
  switch (language) {
    case 'nl':
      return nlNL.components.MuiDataGrid.defaultProps.localeText
    case 'de':
      return deDE.components.MuiDataGrid.defaultProps.localeText
    default:
      return undefined
  }
}
