import { FC } from 'react'
import {
  Route,
  RouteProps,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'

import { useUserStore } from 'src/common/stores/UserStore'

import type { RenderProps } from './utils'

/**
 * Route that is used for Okta to login, but it should redirect your if you are already logged in.
 */
export const CallbackRoute: FC<RouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { user } = useUserStore()
  const history = useHistory()
  const location = useLocation()
  const match = useRouteMatch()

  const getContent = (props: RenderProps): JSX.Element => {
    if (user.isAuthenticated) {
      console.warn('Redirected unneeded access to callback route')
      history.push('/dashboard')
    } else {
      return (
        <Component
          history={history}
          location={location}
          match={match}
          {...props}
        />
      )
    }
  }

  return <Route {...rest} render={(props: RenderProps) => getContent(props)} />
}
