import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

import { getCheckboxDiffs } from '../lib'
import { CardRowDiff, InkoperPlaatRow } from '../types'

type StoreState = {
  cardRows: InkoperPlaatRow[]
  mustRebuildCardRows: boolean
  oldCardRows: InkoperPlaatRow[]
}

type Actions = {
  /* store */
  resetCardRowsState: () => void
  /* computables */
  getCardRowsDiffs: () => CardRowDiff[]
  areCardRowsDirty: () => boolean
  /* card rows */
  addCardRow: (cr: InkoperPlaatRow) => void
  removeCardRow: (cardNumber: number) => void
  setCardRows: (crs: InkoperPlaatRow[]) => void
  setMustRebuildCardRows: (input: boolean) => void
  setOldCardRows: (ocrs: InkoperPlaatRow[]) => void
  updateCardRow: (cr: InkoperPlaatRow) => void
}

const storeName = 'CardRowsStore'

const initialStoreState: StoreState = {
  cardRows: [],
  mustRebuildCardRows: true,
  oldCardRows: [],
}

export const useCardRowsStore = create<StoreState & Actions>()(
  devtools(
    persist(
      (set, get: () => any) => ({
        ...initialStoreState,
        /* store */
        resetCardRowsState: () =>
          set(
            () => initialStoreState,
            false, // replace or not replace - that is the question!
            'resetCardRowsState'
          ),
        /* computables */
        getCardRowsDiffs: () =>
          getCheckboxDiffs(get().cardRows, get().oldCardRows),
        areCardRowsDirty: () => {
          const newOne = JSON.stringify({ ...get().cardRows })
          const oldOne = JSON.stringify({ ...get().oldCardRows })
          return newOne !== oldOne
        },
        /* card rows */
        addCardRow: (cr: InkoperPlaatRow) =>
          // add cr to both arrays
          set(
            (state: StoreState) => ({
              cardRows: [...state.cardRows, cr],
              oldCardRows: [...state.oldCardRows, cr],
            }),
            false,
            'addCardRow'
          ),
        removeCardRow: (cardNumber: number) =>
          set(
            (state: StoreState) => ({
              cardRows: [
                ...state.cardRows.filter(
                  cr => cr?.koperPlaatNummer !== cardNumber
                ),
              ],
              oldCardRows: [
                ...state.oldCardRows.filter(
                  cr => cr?.koperPlaatNummer !== cardNumber
                ),
              ],
            }),
            false,
            'removeCardRow'
          ),
        setCardRows: (crs: InkoperPlaatRow[]) =>
          set(
            () => ({
              cardRows: [...crs],
            }),
            false,
            'setCardRows'
          ),
        setMustRebuildCardRows: (input: boolean) =>
          set(
            () => ({
              mustRebuildCardRows: input,
            }),
            false,
            'setMustRebuildCardRows'
          ),
        setOldCardRows: (ocrs: InkoperPlaatRow[]) =>
          set(
            () => ({
              oldCardRows: [...ocrs],
            }),
            false,
            'setOldCardRows'
          ),
        updateCardRow: (cr: InkoperPlaatRow) =>
          set(
            (state: StoreState) => {
              const oldCard = state.cardRows.find(
                card => card.koperPlaatNummer === cr.koperPlaatNummer
              )
              const index = state.cardRows.indexOf(oldCard)
              const before = state.cardRows.slice(0, index)
              const after = state.cardRows.slice(index + 1)
              return {
                cardRows: [...before, cr, ...after],
              }
            },
            false,
            'updateCardRow'
          ),
      }),
      {
        name: 'card-rows-storage',
        storage: createJSONStorage(() => sessionStorage),
      }
    ),
    { name: storeName }
  )
)
