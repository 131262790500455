import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

type StoreState = {
  mustRefetchBuyer: boolean
  oldBuyerNumber: number
  buyerNumber: number
  buyerName: string
  buyerGlnCode: number
}

type Actions = {
  /* store */
  resetBuyerState: () => void
  /* computables */
  isDirty: () => boolean
  /* buyer */
  setMustRefetchBuyer: (input: boolean) => void
  setOldBuyerNumber: (obnr: number) => void
  setBuyerNumber: (bnr: number) => void
  setBuyerName: (bn: string) => void
  setBuyerGlnCode: (bgc: number) => void
}

const storeName = 'BuyerStore'

const initialStoreState: StoreState = {
  mustRefetchBuyer: false,
  oldBuyerNumber: 0,
  buyerNumber: 0,
  buyerName: '',
  buyerGlnCode: 0,
}

const settingsSlice = (set: any, get: () => any) => ({
  ...initialStoreState,
  /* store */
  resetBuyerState: () =>
    set(
      () => initialStoreState,
      false, // replace or not replace - that is the question!
      'resetBuyerState'
    ),
  /* computables */
  isDirty: () => get().buyerNumber !== get().oldBuyerNummer,
  /* buyer */
  setMustRefetchBuyer: (input: boolean) =>
    set(
      () => ({
        mustRefetchBuyer: input,
      }),
      false,
      'setMustRefetchBuyer'
    ),
  setOldBuyerNumber: (obnr: number) =>
    set(
      () => ({
        oldBuyerNumber: obnr ?? 0,
      }),
      false,
      'setOldBuyerNumber'
    ),
  setBuyerNumber: (bnr: number) =>
    set(
      () => ({
        buyerNumber: bnr ?? 0,
      }),
      false,
      'setBuyerNumber'
    ),
  setBuyerName: (bn: string) =>
    set(
      () => ({
        buyerName: bn || '',
      }),
      false,
      'setBuyerName'
    ),
  setBuyerGlnCode: (bgc: number) =>
    set(
      () => ({
        buyerGlnCode: bgc,
      }),
      false,
      'setBuyerGlnCode'
    ),
})

export const useBuyerStore = create<StoreState & Actions>()(
  devtools(
    persist(settingsSlice, {
      name: 'buyer-storage',
      storage: createJSONStorage(() => sessionStorage),
    }),
    { name: storeName }
  )
)
