import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import type { ChildrenType } from './types'

export const TanstackProvider = ({ children }: ChildrenType): JSX.Element => (
  <QueryClientProvider
    client={
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 1000 * 60,
          },
        },
      })
    }
  >
    {children}
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
)
