// eslint-disable-next-line
import { useSnackbar } from 'notistack'

import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, Route, Switch } from 'react-router-dom'

import { Button } from '@mui/material'
import Box from '@mui/material/Box'
import { Cookiebar, OnlineOfflineAlert } from '@rfh-core/components'
import theme from '@rfh-core/theme'

import { clearStorage } from 'src/common'
import { HomeRoute } from 'src/common/components/HomeRoute'
import { LoginRoute } from 'src/common/components/LoginRoute'
import SnackbarUtils from 'src/common/utils/SnackbarUtils'
import NotFound from 'src/views/notfound/NotFound'

import { CallbackRoute } from '../guards/CallbackRoute'
import { InternalRoutes } from '../internalRoutes'

export const Routes: FC = () => {
  /*
   * Hooks
   */
  const { t } = useTranslation()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  /*
   * Side Effects
   */
  useEffect(() => {
    SnackbarUtils.setSnackBar(enqueueSnackbar, closeSnackbar)
    clearStorage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /*
   * Render
   */
  return (
    <Box flexGrow={1} maxWidth={'100%'} mb={-7}>
      <OnlineOfflineAlert
        offlineMessage={t('onlineOfflineAlert.offline')}
        onlineMessage={t('onlineOfflineAlert.online')}
      />
      <Switch>
        {/* Workaround for Navigate while keeping the query params intact */}
        <Route path='/' exact component={HomeRoute} />
        {/* <Route path='/logout' /> */}
        <CallbackRoute path='/callback' component={LoginRoute} />
        <Route path='/dashboard' component={InternalRoutes} />
        <Route component={NotFound} />
      </Switch>
      <Cookiebar
        title={t('cookieBar.title')}
        messageText={t('cookieBar.text')}
        LinkComponent={
          <Button
            component={Link}
            to={t('cookieBar.link')}
            variant='text'
            sx={{
              color: theme.rfhColors.white,
            }}
          >
            {t('cookieBar.linkText')}
          </Button>
        }
      />
    </Box>
  )
}
