import type { ChildrenType, ProviderType } from './types'

export const composeProviders =
  (...providers: ProviderType[]): any =>
  ({ children }: Readonly<ChildrenType>): JSX.Element =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    providers.reduceRight(
      (child, Provider) => <Provider>{child}</Provider>,
      <>{children}</>
    )
