import { LinearProgress } from '@mui/material'
import { SxProps, Theme } from '@mui/material/styles'
import type { DataGridPremiumProps, GridColDef } from '@mui/x-data-grid-premium'
import { DataGridPremium } from '@mui/x-data-grid-premium'

import { gridHeaderStyles, rowsPerPageOptions } from 'src/common'
import { allGridsDefaults } from 'src/common/constants'
import type { GridName } from 'src/common/types'
import { getLocale } from 'src/common/utils/i18nHelpers'

import CustomGridToolbar from './CustomGridToolbar'
import useDataGrid from './useDataGrid'

type CustomDataGridProps = {
  columns: GridColDef[]
  disableAggregation?: boolean
  filterMode?: 'server' | 'client'
  getRowId: (row: any) => any
  gridName: GridName
  gridProps?: any
  hideHorizontalScrollbar?: boolean
  language: string
  pagination?: boolean
  paginationMode?: 'server' | 'client'
  rows: any[]
  sortingMode?: 'server' | 'client'
  toolbarProps?: Record<string, unknown>
  sx?: SxProps<Theme>
}

export default function CustomDataGrid({
  columns,
  disableAggregation = true,
  filterMode = 'server',
  getRowId,
  gridName,
  gridProps,
  hideHorizontalScrollbar = false,
  language,
  pagination = true,
  paginationMode = 'server',
  rows,
  sortingMode = 'server',
  toolbarProps,
  sx = {},
}: Readonly<CustomDataGridProps>): JSX.Element {
  const gridDefaults = allGridsDefaults[gridName]
  const { initialState } = gridDefaults
  const {
    apiRef,
    gridState,
    orderedVisibleColumns,
    saveChangedPaginationModel,
    saveChangedSortModel,
    saveChangedColumnVisibilityModel,
    saveChangedColumnOrder,
  } = useDataGrid({
    gridName,
    columns,
  })

  // Remember: don't change the defaultGridProps here unless you know what you are doing!
  const defaultGridProps: Partial<DataGridPremiumProps> = {
    apiRef,
    columns: orderedVisibleColumns,
    disableDensitySelector: true,
    slots: { toolbar: CustomGridToolbar, loadingOverlay: LinearProgress },
    slotProps: {
      pagination: {
        showFirstButton: true,
        showLastButton: true,
      },
      toolbar: toolbarProps,
    },
    disableRowGrouping: true,
    getRowId,
    localeText: getLocale(language),
    onPaginationModelChange: saveChangedPaginationModel,
    onSortModelChange: saveChangedSortModel,
    onColumnVisibilityModelChange: saveChangedColumnVisibilityModel,
    onColumnOrderChange: saveChangedColumnOrder,
    rowCount: gridProps?.rowCount ?? 0,
    rows,
  }

  const defaultSx: SxProps<Theme> = {
    ...gridHeaderStyles,
    width: '100%',
    '& .MuiDataGrid-virtualScroller[role="presentation"]': {
      overflowX: hideHorizontalScrollbar ? 'hidden' : 'inherit',
    },
  }

  return (
    <DataGridPremium
      autoHeight
      columnVisibilityModel={gridState?.columns?.columnVisibilityModel ?? {}}
      disableAggregation={disableAggregation}
      disableColumnFilter // We have not yet implemented the filter with the OData client so disabled for now
      disableRowSelectionOnClick
      disableVirtualization
      filterMode={filterMode}
      initialState={{ ...initialState, ...gridState }}
      onRowOrderChange={gridProps?.onRowOrderChange}
      pagination={pagination}
      paginationMode={paginationMode}
      pageSize={
        gridState?.pagination?.paginationModel?.pageSize ??
        rowsPerPageOptions[0]
      }
      pageSizeOptions={rowsPerPageOptions}
      rowReordering={gridProps?.rowReordering}
      sortingMode={sortingMode}
      {...defaultGridProps}
      {...gridProps}
      sx={{
        ...defaultSx,
        ...sx,
      }}
    />
  )
}
