import { Box, Container } from '@mui/material'

export default function WorkInProgress(): JSX.Element {
  const image = window.location.origin + '/assets/images/under-construction.png'
  return (
    <Container
      maxWidth='md'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '75vh',
      }}
    >
      <Box
        component={'img'}
        src={image}
        alt='website is under construction'
        style={{
          width: '70%',
          height: '70%',
          objectFit: 'cover',
        }}
      />
    </Container>
  )
}
