import { ApiClientProvider } from './ApiClientProvider'
import { AuthenticationProvider } from './AuthenticationProvider'
import { LocalizationProvider } from './LocalizationProvider'
import { OktaProvider } from './OktaProvider'
import { composeProviders } from './providerHelpers'
import { TanstackProvider } from './TanstackProvider'
import { ThemeProvider } from './ThemeProvider'
import { UserProvider } from './UserProvider'

/**
 * This provider is the place to include all the providers that you want the application to have access
 * to globally, for this to work it needs to be the first child of your router. Keep in mind the order of the providers
 * is important since some providers depend on each other.
 */
export const GlobalProvider = composeProviders(
  OktaProvider,
  AuthenticationProvider,
  UserProvider,
  ApiClientProvider,
  LocalizationProvider,
  ThemeProvider,
  TanstackProvider
)
