import { FC } from 'react'

import { Box, Skeleton, Stack } from '@mui/material'
import Grid from '@mui/material/Grid2'

export const PurchaserDetailsSkeleton: FC = () => {
  const dummyArray = [
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
    { id: 6 },
  ]

  return (
    <Grid container spacing={2} sx={{ marginTop: 2 }}>
      <Grid
        size={{ xs: 12 }}
        sx={{
          padding: '0 !important',
          marginLeft: 2,
        }}
      >
        <Grid mb={2}>
          <Skeleton variant={'text'} sx={{ fontSize: '3em', width: '40%' }} />
        </Grid>
        <Grid container flexDirection='row' width={'100%'}>
          <Grid size={{ xs: 4 }} pr={2}>
            <Stack spacing={2}>
              {dummyArray.map(el => (
                <Box display={'flex'} gap={2} key={el.id}>
                  <Skeleton variant='rectangular' width={'35%'} height={40} />
                  <Skeleton variant='rectangular' width={'65%'} height={40} />
                </Box>
              ))}
            </Stack>
          </Grid>
          <Grid size={{ xs: 4 }} px={2}>
            <Stack spacing={2}>
              <Box display={'flex'} gap={2}>
                <Skeleton variant='rectangular' width={'35%'} height={40} />
                <Skeleton variant='rectangular' width={'10%'} height={40} />
              </Box>
              {dummyArray.slice(1).map(el => (
                <Box display={'flex'} gap={2} key={el.id}>
                  <Skeleton variant='rectangular' width={'35%'} height={40} />
                  <Skeleton variant='rectangular' width={'65%'} height={40} />
                </Box>
              ))}
            </Stack>
          </Grid>
          <Grid size={{ xs: 4 }} px={2}>
            <Stack spacing={2}>
              {dummyArray.slice(0, 2).map(el => (
                <Box display={'flex'} gap={2} key={el.id}>
                  <Skeleton variant='rectangular' width={'35%'} height={40} />
                  <Skeleton variant='rectangular' width={'65%'} height={40} />
                </Box>
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
