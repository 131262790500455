import { Box, type SxProps, type Theme } from '@mui/material'

export const Divider = (props: SxProps): JSX.Element => {
  const hrStyle = {
    border: (theme: Theme) => `1px solid ${theme.rfhColors.grey[200]}`,
    width: '100%',
  }

  return <Box component={'hr'} sx={{ ...hrStyle, ...props }} />
}
