import { Route, Switch } from 'react-router-dom'

import { useDevToolsStore } from 'src/dev-tools'
import { WorkInProgress } from 'src/dev-tools/components'
import {
  BuyersCards,
  ClientLimits,
  ManageBuyersCards,
  ManagePurchaser,
  PurchaserLimits,
  Purchasers,
} from 'src/features'
import { BuyersCardsHistory, HomePage, NotFound, ProfilePage } from 'src/views'

import {
  AccountManagementRoute,
  ContractbeheerderRoute,
  SecureRoute,
} from '../guards'

export default function InternalRoutes(): JSX.Element {
  const { features, shouldFeatureBeEnabled } = useDevToolsStore()

  return (
    <Switch>
      <SecureRoute path='/dashboard' exact component={HomePage} />
      <SecureRoute
        path='/dashboard/buyerscard/:cardId'
        exact
        component={BuyersCards}
      />
      <AccountManagementRoute
        path='/dashboard/buyerscards/add'
        exact
        component={ManageBuyersCards}
      />
      <AccountManagementRoute
        path='/dashboard/buyerscards/:cardId/edit'
        exact
        component={ManageBuyersCards}
      />
      <SecureRoute path='/dashboard/buyerscards' component={BuyersCards} />
      <SecureRoute
        path='/dashboard/buyerscards-history'
        component={BuyersCardsHistory}
      />
      <SecureRoute path='/dashboard/purchasers' exact component={Purchasers} />
      <ContractbeheerderRoute
        path='/dashboard/purchasers/add'
        exact
        component={ManagePurchaser}
      />
      <ContractbeheerderRoute
        path='/dashboard/purchasers/:id'
        exact
        component={ManagePurchaser}
      />
      {shouldFeatureBeEnabled(features?.INKOPERLIMIETEN) && (
        <SecureRoute
          path='/dashboard/purchaser-limits'
          exact
          component={PurchaserLimits}
          enabled={shouldFeatureBeEnabled(features.INKOPERLIMIETEN)}
        />
      )}
      {shouldFeatureBeEnabled(features?.KLANTLIMIETEN) && (
        <SecureRoute
          path='/dashboard/client-limits'
          exact
          component={ClientLimits}
          enabled={shouldFeatureBeEnabled(features.KLANTLIMIETEN)}
        />
      )}
      {shouldFeatureBeEnabled(features?.WIP) && (
        <SecureRoute
          path='/dashboard/work-in-progress'
          component={WorkInProgress}
          enabled={shouldFeatureBeEnabled(features.WIP)}
        />
      )}
      {/* SETTINGS */}
      <SecureRoute path='/dashboard/profile' component={ProfilePage} />
      <Route component={NotFound} />
    </Switch>
  )
}
