import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'

import { Entity, useDeleteEntity } from 'src/common/hooks'
import SnackbarUtils from 'src/common/utils/SnackbarUtils'

type DeletePurchaserDialogProps = {
  idToBeDeleted: number
  open: boolean
  onClose: () => void
  onCancel: () => void
  onRefresh: () => void
}

export const DeletePurchaserDialog: FC<DeletePurchaserDialogProps> = ({
  idToBeDeleted,
  open,
  onClose: close,
  onCancel,
  onRefresh: refresh,
}) => {
  const { t } = useTranslation()
  const deleteEntity = useDeleteEntity(Entity.IInkoper)

  const onSubmit = useCallback(async (): Promise<void> => {
    if (deleteEntity.isLoading) return Promise.resolve()
    try {
      await deleteEntity.mutateAsync(idToBeDeleted)
      refresh()
      close()
    } catch (error) {
      SnackbarUtils.error(`${error}`)
    }
  }, [close, deleteEntity, idToBeDeleted, refresh])

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{t('purchaser.deleteDialog.title')}</DialogTitle>
      <DialogContent>{t('purchaser.deleteDialog.description')}</DialogContent>
      <DialogActions>
        <Button variant='text' onClick={onCancel}>
          {t('common.no')}
        </Button>
        <Button onClick={onSubmit} disabled={deleteEntity.isLoading}>
          {t('common.yes')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
