import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { debounce, FormControl, InputAdornment, TextField } from '@mui/material'

import { IKoper } from 'src/common/services/client'
import { BuyersCardsDialogAvailableBuyers } from 'src/features/buyerscards/components'

import { ThemeConfig } from '../config'
import { Entity, useFetchOne } from '../hooks'
import { useUserStore } from '../stores/UserStore'

type CustomerInputProps = {
  changeCustomerNumber: (newNumber: number) => void
  changeValid: (newValue: boolean) => void
  showOnlyAvailableBuyers: boolean
  disabled?: boolean
  value?: number
  sx?: any
}

export default function CustomerInput({
  disabled,
  changeCustomerNumber,
  showOnlyAvailableBuyers,
  changeValid,
  value,
  sx,
}: Readonly<CustomerInputProps>): JSX.Element {
  const [customerNumber, setCustomerNumber] = useState<number>(0)
  const [numberToSearch, setNumberToSearch] = useState<number>(undefined)
  const { t } = useTranslation()
  const { user } = useUserStore()
  const { data: koper, isError } = useFetchOne<IKoper>(
    { key: numberToSearch },
    Entity.IKoper
  )

  const changeValue = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value ?? 0)
    if (newValue < 999999999 && newValue >= 0) {
      setCustomerNumber(newValue)
      bouncedNewInput(newValue)
    }
  }

  const selectValue = (event: any) => {
    const newValue = Number(event.target.value ?? 0)
    if (newValue < 999999999 && newValue >= 0) {
      setCustomerNumber(newValue)
      newInput(newValue)
    }
  }

  const newInput = (newValue: number) => {
    if (newValue) {
      setNumberToSearch(newValue)
      changeCustomerNumber(newValue)
    } else {
      setCustomerNumber(0)
      setNumberToSearch(undefined)
      changeCustomerNumber(undefined)
      changeValid(false)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const bouncedNewInput = useCallback(debounce(newInput, 1500), [
    user.accessToken,
  ])

  const getHelperText = (): string => {
    if (value && koper) {
      return koper?.kopernaam
    }
    return ''
  }

  useEffect(() => {
    if (customerNumber === 0) {
      setCustomerNumber(value)
    }
    newInput(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <FormControl fullWidth>
      <TextField
        label={t('customerInput.customerNumber')}
        id='klantnummer'
        name='klantnummer'
        disabled={disabled}
        error={isError}
        type='number'
        value={customerNumber !== 0 ? customerNumber : ''}
        placeholder={t('customerInput.placeholder')}
        onChange={changeValue}
        helperText={getHelperText()}
        slotProps={{
          input: {
            endAdornment: !disabled && (
              <InputAdornment position='end'>
                <BuyersCardsDialogAvailableBuyers
                  onChangeBuyer={selectValue}
                  showOnlyAvailableBuyers={showOnlyAvailableBuyers}
                />
              </InputAdornment>
            ),
          },
          formHelperText: {
            sx: {
              position: 'absolute',
              top: ThemeConfig.spacing.xl * 9,
            },
          },
        }}
        sx={sx}
      />
    </FormControl>
  )
}
