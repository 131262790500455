/* eslint-disable */

import { Config } from 'src/common/config/Config'
import i18n from 'src/i18n/index'

import SnackbarMessage from '../components/SnackbarMessage'
import { HttpCode } from '../constants'
import { getLocalItem } from '../utils/OfflineManager'
import SnackbarUtils from '../utils/SnackbarUtils'

class ClientBase {
  authToken = ''

  public constructor() {
    this.authToken = (
      getLocalItem('okta-token-storage') as any
    ).accessToken.accessToken
  }

  protected getBaseUrl(arg0: string, baseUrl: string): string {
    return Config.api.host
  }

  protected setAuthToken(token: string) {
    this.authToken = token
  }

  protected transformOptions(options: any): Promise<any> {
    if (!options?.headers) {
      options.headers = new Headers()
    }

    // We do not use the If-Match header and it is not allowed by our CORS policy so we need to remove if
    if (options.headers['If-Match']) {
      delete options.headers['If-Match']
    }

    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${this.authToken}`,
      'Access-Control-Allow-Origin': '*',
      'Accept-Language': i18n.language,
    }

    return Promise.resolve(options)
  }

  protected async transformResult(
    url_: string,
    _response: Response,
    actionResult: any
  ) {
    // We need to clone the request because you are only allowed to read it once. We need to read it a second time to get the correct metadata and typings.
    const requestClone = _response.clone()

    try {
      // filter out codes with no response JSON body
      switch (_response.status) {
        // used both for creation and updates (alas!)
        case HttpCode.CREATED:
          SnackbarUtils.success(i18n.t('common.statusCodes.success'))
          break
        case HttpCode.NOT_FOUND:
          // Since the request with the statuscode 204 does not contain a body we can return undefined
          SnackbarUtils.warning(i18n.t('common.statusCodes.notFound'))
          return Promise.resolve(null)
        case HttpCode.NO_CONTENT:
          return Promise.resolve(undefined)
        case HttpCode.FORBIDDEN:
          SnackbarUtils.warning(
            i18n.t('common.statusCodes.code', { code: _response.status })
          )
          return Promise.resolve(undefined)
        default:
          break
      }

      const clientResult = await actionResult(_response)
      const result = await requestClone.json()
      return {
        ...result,
        cleanedResponse: clientResult,
      }
    } catch (error) {
      if (_response.status === HttpCode.CREATED)
        return Promise.resolve(undefined)
      const requestError = await requestClone.json()

      if (requestError.errors) {
        for (const [key, value] of Object.entries(requestError.errors)) {
          if(!value){
            SnackbarUtils.error(key)
          } else if (typeof value === 'string'){
            SnackbarUtils.error(`${key}: ${value}`)
          } else {
            SnackbarUtils.error(SnackbarMessage(Object.values(value), key))
          }
        }
      } else if (requestError.value) {
        SnackbarUtils.error(requestError.value)
      } else if (requestError.status
        && requestError.status < 500) {
        SnackbarUtils.error(
          i18n.t('common.statusCodes.code', { code: `${requestError.status}` })
        )
      } else {
        SnackbarUtils.error(i18n.t('common.genericError'))
      }
      return Promise.resolve(undefined)
    }
  }
}

export default ClientBase
