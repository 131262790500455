import { FC, forwardRef, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import {
  AppBar,
  Box,
  Container,
  Dialog,
  DialogContent,
  IconButton,
  Slide,
  Toolbar,
} from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import { CloseIcon } from '@rfh-core/icons'

interface IProps {
  title: string
  open: boolean
  handleClose?: () => void
  children: React.ReactNode
}

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>
  ) => <Slide direction='up' ref={ref} {...props} />
)

const FullScreenDialog: FC<IProps> = (props: IProps): ReactElement => {
  /*
  State
  */
  const { open, handleClose, title, children } = props
  const { t } = useTranslation()

  /*
  Render
  */
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar color='default' position='relative' elevation={0}>
        <Container maxWidth='xl'>
          <Toolbar disableGutters>
            <IconButton
              data-testid='closeBtn'
              edge='start'
              color='inherit'
              onClick={handleClose}
              aria-label={t('common.close')}
            >
              <CloseIcon />
            </IconButton>
            <Box
              width={'100%'}
              display={'flex'}
              alignItems={'center'}
              fontWeight='bold'
              ml={2}
            >
              {title}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <DialogContent
        sx={{
          paddingLeft: 0,
          paddingRight: 0,
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Container
          maxWidth='xl'
          sx={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          {children}
        </Container>
      </DialogContent>
    </Dialog>
  )
}

export default FullScreenDialog
