import type { GridExceljsProcessInput } from '@mui/x-data-grid-premium'
import theme from '@rfh-core/theme'

const minWidth = 15

const fontSettings = {
  color: { argb: theme.rfhColors.black },
  family: 2,
  name: 'Arial',
  size: 12,
  wrap: true,
}

function setAlignment(cell: any) {
  cell.alignment =
    typeof cell.value === 'number'
      ? { horizontal: 'right' }
      : { horizontal: 'left', wrapText: true }
}

const exceljsPostProcess = ({ worksheet }: GridExceljsProcessInput): void => {
  worksheet.eachRow(row => {
    row.eachCell(cell => {
      if (cell?.numFmt?.includes('dd.mm.yyyy')) {
        cell.numFmt = cell.numFmt.replace('dd.mm.yyyy', 'dd-mm-yyyy')
      }
      setAlignment(cell)
    })
  })
  // add a text after the data
  worksheet.addRow({})
  worksheet.addRow({})
  const lastRow = worksheet.addRow([
    'BELANGRIJK! Uitsluitend voor eigen gebruik van RFH medewerkers',
  ])

  worksheet.eachRow((row, rowNumber) => {
    row.eachCell(cell => {
      cell.font = { ...fontSettings, bold: rowNumber === 1 }
    })
  })

  const firstRow = worksheet.getRow(1)
  firstRow.eachCell((cell, colNumber) => {
    const column = worksheet.getColumn(colNumber)
    if (column.width < minWidth) {
      column.width = minWidth
    }
  })

  lastRow.getCell(1).font = { ...fontSettings, bold: true }
}

export const excelOptions = { exceljsPostProcess }

export const getToolbarProps = (
  title = document.title
): Record<string, unknown> => ({
  csvOptions: { fileName: title },
  excelOptions: { fileName: title, ...excelOptions },
  printOptions: { disableToolbarButton: true },
})
