export interface ITheme {
  spacing: {
    xxs: number
    xs: number
    s: number
    sm: number
    m: number
    l: number
    xl: number
  }
}

export const ThemeConfig: ITheme = {
  spacing: {
    xxs: 0.25,
    xs: 0.5,
    s: 1,
    sm: 2,
    m: 4,
    l: 6,
    xl: 8,
  },
}
