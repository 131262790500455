// eslint-disable-next-line
import { useTranslation } from 'react-i18next'

import { Chip, Container, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import theme from '@rfh-core/theme'

import BooleanTag from 'src/common/components/BooleanTag'
import { Config } from 'src/common/config/Config'
import { ThemeConfig } from 'src/common/config/SpacingConfig'
import { useUser } from 'src/common/providers/UserProvider'

import { OverRuling, Property } from './components'

export default function ProfilePage(): JSX.Element {
  const PANEL_HEIGHT = 200
  const user = useUser()
  const mayOverrideRoles = user.isApplicationManager && Config.env !== 'prd'
  const { t } = useTranslation()

  const showLoginAccount = (): string | JSX.Element => {
    if (user.preferredUsername && user.sub) {
      return user.preferredUsername === user.sub ? (
        user.preferredUsername
      ) : (
        <Grid container justifyContent={'flex-start'} alignItems={'center'}>
          {user.preferredUsername}
          <Chip color='info' label={user.sub} sx={{ ml: 2 }} />
        </Grid>
      )
    }
    return t('common.loading')
  }

  return (
    <Container maxWidth={'lg'} sx={{ p: ThemeConfig.spacing.sm }}>
      <Typography
        variant={'h2'}
        mt={ThemeConfig.spacing.xl}
        mb={ThemeConfig.spacing.sm}
      >
        {t('common.titles.profilePage')}
      </Typography>

      {/* My account section */}
      <Grid container mb={ThemeConfig.spacing.sm}>
        <Grid
          container
          flexDirection={'column'}
          justifyContent={'flex-start'}
          alignItems={'start'}
          p={ThemeConfig.spacing.m}
          size={{ xs: 12 }}
          sx={{ bgcolor: theme.rfhColors.white }}
        >
          <Property
            name={t('profilePage.name')}
            value={user.name ? user.name : t('common.loading')}
          />
          <Property
            name={t('profilePage.account')}
            value={showLoginAccount()}
          />
          <Property
            name={t('profilePage.email')}
            value={user.email ? user.email.toLowerCase() : t('common.loading')}
          />
        </Grid>
      </Grid>

      <Grid container my={ThemeConfig.spacing.xl}>
        {/* Permissions section */}
        <Grid
          container
          flexDirection={'column'}
          justifyContent={'flex-start'}
          pr={mayOverrideRoles ? 1.5 : 0}
          size={{ xs: mayOverrideRoles ? 6 : 12 }}
        >
          <Typography
            variant={'h2'}
            mt={ThemeConfig.spacing.xl}
            mb={ThemeConfig.spacing.sm}
          >
            {t('profilePage.permissions')}
          </Typography>
          <Grid
            container
            flexDirection={'column'}
            justifyContent={'flex-start'}
            alignItems={'flex-start'}
            minHeight={PANEL_HEIGHT}
            p={ThemeConfig.spacing.m}
            sx={{ backgroundColor: 'white' }}
          >
            <Property
              name={t('profilePage.readOnlyRole')}
              value={<BooleanTag value={user.isReadOnly} />}
            />
            <Property
              name={t('profilePage.applicationManagerRole')}
              value={<BooleanTag value={user.isApplicationManager} />}
            />
            <Property
              name={t('profilePage.accountManagementRole')}
              value={<BooleanTag value={user.isAccountManagement} />}
            />
            <Property
              name={t('profilePage.contractManagementRole')}
              value={<BooleanTag value={user.isContractBeheerder} />}
            />
          </Grid>
        </Grid>
        {/* Override roles section */}
        {mayOverrideRoles && (
          <Grid
            container
            flexDirection={'column'}
            justifyContent={'flex-start'}
            pl={1.5}
            size={{ xs: 6 }}
          >
            <Typography
              variant={'h2'}
              mt={ThemeConfig.spacing.xl}
              mb={ThemeConfig.spacing.sm}
            >
              {t('profilePage.overrulingTitle')}
            </Typography>
            <OverRuling minHeight={PANEL_HEIGHT} p={ThemeConfig.spacing.m} />
          </Grid>
        )}
      </Grid>
    </Container>
  )
}
