/* eslint-disable id-blacklist */
import { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Card,
  CardContent,
  CardMedia,
  Container,
  Typography,
} from '@mui/material'
import Grid from '@mui/material/Grid2'

import { ThemeConfig } from 'src/common/config/SpacingConfig'

const BuyersCardsHistory: FC = (): ReactElement => {
  /*
  Hooks
  */
  const { t } = useTranslation()

  /*
  Render
  */
  return (
    <Container maxWidth='xl' sx={{ padding: ThemeConfig.spacing.sm }}>
      <Grid
        container
        spacing={2}
        sx={{
          width: '100%',
          margin: '0',
        }}
      >
        <Grid
          size={{ xs: 12 }}
          sx={{
            padding: '0 !important',
          }}
        >
          <Typography
            variant={'h1'}
            sx={{
              my: ThemeConfig.spacing.sm,
            }}
          >
            {t('common.titles.buyersCardsHistory')}
          </Typography>
        </Grid>

        <Grid size={{ sm: 12 }}>
          <Card>
            <CardContent>
              <Typography variant='h1'>
                {t('buyersCardsHistory.subTitle')}
              </Typography>
              <Typography variant='subtitle1'>
                {t('common.titleDescriptions.buyersCardsHistory')}
              </Typography>
              <Typography variant='body1'>
                {t('buyersCardsHistory.text')}
              </Typography>
            </CardContent>
            <CardMedia component='img' src='/assets/images/buyerscards.jpg' />
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}

export default BuyersCardsHistory
