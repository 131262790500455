/* eslint-disable @typescript-eslint/quotes */
import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { SearchIcon } from '@rfh-core/icons'

import type { ApiClientListFilter } from 'src/common/hooks'
import { Entity, useFetchMany } from 'src/common/hooks'
import { IKoper } from 'src/common/services/client'

import BuyersCardsDialogAvailableBuyersItem from './BuyersCardsDialogAvailableBuyersItem'

type BuyersCardsDialogAvailableBuyersProps = {
  onChangeBuyer: (evt: any) => void
  showOnlyAvailableBuyers: boolean
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const BuyersCardsDialogAvailableBuyers: FC<
  BuyersCardsDialogAvailableBuyersProps
> = ({ onChangeBuyer, showOnlyAvailableBuyers }) => {
  const { t } = useTranslation()
  const { handleSubmit } = useForm()
  const [openBuyer, setOpenBuyer] = useState<boolean>(false)
  const [searchStringBuyerName, setSearchStringBuyerName] = useState<string>('')
  const [filter, setFilter] = useState<ApiClientListFilter>({
    top: 20,
    filter: showOnlyAvailableBuyers
      ? "contains(Blokkeerindicatie, 'N')"
      : undefined,
  })

  const { data: buyers, isLoading } = useFetchMany<IKoper>(
    filter,
    Entity.IKoper,
    openBuyer
  )

  const handleClickOpen = () => {
    setOpenBuyer(true)
  }

  const handleClose = () => {
    setOpenBuyer(false)
  }

  const itemSelected = (evt: any) => {
    onChangeBuyer(evt)
    handleClose()
  }

  const handleUserKeyPress = (evt: any) => {
    if (evt.key === 'Enter' && !evt.shiftKey) {
      evt.preventDefault()
      handleSubmit(onSubmit)()
    }
  }

  const onSubmit = () => {
    let newFilter
    if (showOnlyAvailableBuyers) {
      newFilter = searchStringBuyerName
        ? `contains(kopernaam, '${searchStringBuyerName}') and contains(Blokkeerindicatie, 'N')`
        : "contains(Blokkeerindicatie, 'N')"
    } else {
      newFilter = searchStringBuyerName
        ? `contains(kopernaam, '${searchStringBuyerName}')`
        : undefined
    }
    const apiClientFilter: ApiClientListFilter = {
      top: 20,
      filter: newFilter,
    }
    setFilter(apiClientFilter)
  }

  const handleFilterBuyerNumber = (evt: any): void => {
    setSearchStringBuyerName(evt.target.value)
  }

  /*
   * Render
   */
  return (
    <>
      <IconButton onClick={handleClickOpen} size='large'>
        <SearchIcon />
      </IconButton>
      <Dialog open={openBuyer} onClose={handleClose} fullWidth>
        <DialogTitle>
          <Typography
            variant={'h4'}
            style={{ marginBottom: '16px', fontSize: '1rem' }}
          >
            {t('buyersCards.addBuyersCards.zoekKopers')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              display: 'flex',
              marginBottom: '0.7rem',
            }}
          >
            <TextField
              id='kopernaam'
              name='kopernaam'
              placeholder={t('buyersCards.addBuyersCards.customerName')}
              fullWidth
              onChange={handleFilterBuyerNumber}
              onKeyDown={handleUserKeyPress}
            />
            <Button
              variant='contained'
              onClick={handleSubmit(onSubmit)}
              disabled={isLoading}
              sx={{
                marginLeft: '0.5rem',
              }}
            >
              {t('common.search')}
            </Button>
          </form>
          <Table
            style={{
              minHeight: '100px',
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    textAlign: 'left',
                    verticalAlign: 'top',
                  }}
                >
                  {t('buyersCards.addBuyersCards.number')}
                </TableCell>
                <TableCell
                  style={{
                    textAlign: 'left',
                    verticalAlign: 'top',
                  }}
                >
                  {t('buyersCards.addBuyersCards.name')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!buyers?.records.length && (
                <TableRow>
                  <TableCell colSpan={2}>{t('common.noResults')}</TableCell>
                </TableRow>
              )}
              {buyers?.records.map((buyer: IKoper) => (
                <BuyersCardsDialogAvailableBuyersItem
                  onClick={itemSelected}
                  key={buyer.kopernummer}
                  buyer={buyer}
                ></BuyersCardsDialogAvailableBuyersItem>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions
          sx={{
            mt: '1rem',
            mr: '2.5rem',
            flex: 1,
            justifyContent: 'flex-end',
          }}
        >
          <Button variant='outlined' autoFocus onClick={handleClose}>
            {t('common.back')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
