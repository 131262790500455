import { FC } from 'react'

import { TableCell, TableRow } from '@mui/material'

import { IFloridayUser } from 'src/common/services/client'

type FloridayUserItemProps = {
  onClick: (evt: IFloridayUser) => void
  user: IFloridayUser
}
export const FloridayUserItem: FC<FloridayUserItemProps> = ({
  onClick,
  user,
}) => {
  const submit = () => {
    onClick(user)
  }
  return (
    <TableRow
      style={{
        cursor: 'pointer',
      }}
      key={user.technicalId}
      hover={true}
    >
      <TableCell onClick={submit}>{user.alternateId}</TableCell>
      <TableCell onClick={submit}>{user?.koper?.kopernaam}</TableCell>
    </TableRow>
  )
}
