import type { GridColDef } from '@mui/x-data-grid-premium'

import {
  formatDate,
  formatNumber,
  formatNumberWithDots,
  toCetDateOrDefault,
  toCetDateStringOrDefault,
} from 'src/common'
import i18n from 'src/i18n'

export const getClientLimitsColumns = (): GridColDef[] => [
  {
    field: 'klantNummer',
    headerName: i18n.t('client.clientNumber'),
    headerAlign: 'right',
    align: 'right',
    type: 'number',
    flex: 0.5,
    valueFormatter: value => formatNumber(value),
  },
  {
    field: 'klantNaam',
    headerName: i18n.t('client.clientName'),
    headerAlign: 'left',
    align: 'left',
    flex: 2,
  },
  {
    field: 'limiet',
    headerName: i18n.t('client.limit'),
    headerAlign: 'right',
    align: 'right',
    type: 'number',
    flex: 0.6,
    valueFormatter: value => formatNumberWithDots(value),
  },
  {
    field: 'omzet',
    headerName: i18n.t('client.turnover'),
    headerAlign: 'right',
    align: 'right',
    type: 'number',
    flex: 0.6,
    valueFormatter: value => formatNumberWithDots(value),
  },
  {
    field: 'ingangsdatumDatumTijd',
    headerName: i18n.t('client.startDate'),
    headerAlign: 'center',
    align: 'center',
    type: 'dateTime',
    flex: 1,
    valueGetter: ({ value }) => toCetDateOrDefault(value),
    valueFormatter: ({ value }) =>
      toCetDateStringOrDefault(value, i18n.t('common.dateFormat')),
    renderCell: ({ value }) =>
      formatDate(value, i18n.t('common.dateFormat'), 'CET'),
  },
  {
    field: 'eindDatumDatumTijd',
    headerName: i18n.t('client.endDate'),
    headerAlign: 'center',
    align: 'center',
    type: 'date',
    flex: 0.8,
    valueGetter: ({ value }) => toCetDateOrDefault(value),
    valueFormatter: ({ value }) =>
      toCetDateStringOrDefault(value, i18n.t('common.dateFormatShort')),
    renderCell: ({ value }) =>
      formatDate(value, i18n.t('common.dateFormatShort'), 'CET'),
  },
  {
    field: 'mutatieGebruiker',
    headerName: i18n.t('common.mutationUser'),
    headerAlign: 'left',
    align: 'left',
    flex: 0.8,
    valueFormatter: row => row.value ?? '-',
  },
]
