import { FC } from 'react'
import { Redirect } from 'react-router-dom'

type HomeRouteProps = {
  location: {
    hash: string
    pathname: string
    search: string
    state: unknown
  }
}

export const HomeRoute: FC<HomeRouteProps> = ({ location }) => (
  <Redirect
    to={{
      ...location,
      pathname: location.pathname.replace('/', '/dashboard'),
    }}
  />
)
