import {
  FormControl,
  FormLabel,
  inputBaseClasses,
  inputClasses,
  lighten,
  svgIconClasses,
  SxProps,
} from '@mui/material'
import Grid from '@mui/material/Grid2'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import theme from '@rfh-core/theme'

import { ELEMENT_HEIGHT, noOp } from 'src/common'

type LabelDateProps = {
  disabled?: boolean
  fieldName: string
  label: string
  flowDirection?: 'row' | 'column' | 'column-reverse' | 'row-reverse'
  minDate?: Date
  value?: Date
  onChange?: (date: Date) => void
  sx?: SxProps
} & { [key: string]: any }

export default function LabelDate({
  disabled = false,
  fieldName,
  label,
  flowDirection = 'row',
  minDate,
  value = null,
  onChange = noOp,
  sx,
  ...rest
}: Readonly<LabelDateProps>): JSX.Element {
  return (
    <Grid
      container
      direction={flowDirection}
      justifyContent={'flex-start'}
      alignItems={flowDirection === 'row' ? 'center' : ''}
    >
      <Grid size={{ xs: 12, sm: 5 }}>
        <FormControl>
          <FormLabel
            htmlFor={`id-${fieldName}`}
            sx={{
              color: lighten(theme.rfhColors.black, 0.2),
              whiteSpace: 'nowrap',
              textAlign: 'start',
              fontFamily: 'Halyard-display',
              fontWeight: 'bold',
              fontSize: '1rem',
              mb: flowDirection === 'column' ? 2 : 0,
              ...sx,
            }}
          >
            {label}
          </FormLabel>
        </FormControl>
      </Grid>
      <Grid size={{ xs: 12, sm: 7 }} sx={{ maxHeight: ELEMENT_HEIGHT }}>
        <FormControl fullWidth>
          <DatePicker
            {...rest}
            aria-label={`date picker for ${label.toLowerCase()}`}
            defaultValue={null}
            disabled={disabled}
            {...(minDate && { minDate })}
            value={value}
            onChange={onChange}
            slotProps={{
              textField: {
                sx: {
                  bgcolor: theme.rfhColors.white,
                  [`& ${inputClasses.root}`]: {
                    px: 1,
                    [`& .${svgIconClasses.root}`]: {
                      color: theme.rfhColors.dutchOrange,
                    },
                  },
                  [`& ${inputBaseClasses.root}`]: {
                    border: `1px solid ${theme.rfhColors.grey[200]} !important`,
                  },
                },
              },
            }}
          />
        </FormControl>
      </Grid>
    </Grid>
  )
}
