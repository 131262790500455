import { QueryClient, UseQueryResult } from '@tanstack/react-query'

import { IClient } from 'src/common/services/client'

import { ApiClientListFuncType, EntityType, FuncSort } from './lib.resources'
import { ApiClientListFilter, ListAndCount } from './types'

export const prefetch = <T>(
  queryResult: UseQueryResult<ListAndCount<T>>,
  filter: ApiClientListFilter,
  entity: EntityType,
  func: ApiClientListFuncType,
  client: IClient,
  queryClient: QueryClient
): void => {
  const tempFilter = {
    top: filter?.top ?? 100,
    skip: filter?.skip ?? 0,
    filter: filter?.filter ?? undefined,
    count: filter?.count ?? undefined,
    orderby: filter?.orderby ?? undefined,
    expand: filter?.expand ?? undefined,
  }
  if (filter.skip + filter.top < queryResult.data.count) {
    const newFilter = { ...tempFilter, skip: tempFilter.skip + tempFilter.top }
    const newQueryKey = [entity, FuncSort.list, newFilter]
    queryClient.prefetchQuery<any, any, ListAndCount<T>, typeof newQueryKey>(
      newQueryKey,
      {
        queryFn: () =>
          client[func](
            newFilter.filter,
            newFilter.orderby,
            newFilter.top,
            newFilter.skip,
            newFilter.count,
            newFilter.expand
          ) as Promise<any>,
      }
    )
  }
  if (filter.skip > filter.top) {
    const newFilter = { ...tempFilter, skip: tempFilter.skip - tempFilter.top }
    const newQueryKey = [entity, FuncSort.list, newFilter]
    queryClient.prefetchQuery<any, any, ListAndCount<T>, typeof newQueryKey>(
      newQueryKey,
      {
        queryFn: () =>
          client[func](
            newFilter.filter,
            newFilter.orderby,
            newFilter.top,
            newFilter.skip,
            newFilter.count,
            newFilter.expand
          ) as Promise<any>,
      }
    )
  }
}

export const hasPropertyAndNotNull = (
  obj: IClient,
  method: keyof IClient
): boolean =>
  Boolean(obj) &&
  typeof obj === 'object' &&
  method in obj &&
  typeof obj[method] === 'function'
