import { parseISO } from 'date-fns'
import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz'
import { t } from 'i18next'

import { GridValueFormatterParams } from '@mui/x-data-grid-premium'
import { GridGroupingValueGetterParams } from '@mui/x-data-grid-premium/models/gridGroupingValueGetterParams'

export const getUTCDate = (value: Date): Date =>
  new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate()))

export const utcToday = (): Date => getUTCDate(new Date())

export const utcNever = (): Date => getUTCDate(new Date('2099-12-31T23:59:59'))

export const isDateToday = (date: Date): boolean => {
  if (!date) return false

  const dateString = formatInTimeZone(
    getUTCDate(new Date(date)),
    'UTC',
    'dd-MM-yyyy'
  )
  const dateNowString = formatInTimeZone(
    getUTCDate(new Date()),
    'UTC',
    'dd-MM-yyyy'
  )
  return dateString === dateNowString
}

export const showDateTime = (date: Date): string => {
  if (date) {
    return formatInTimeZone(date, 'CET', t('common.dateFormat'))
  }

  return t('common.notApplicable')
}

/**
 * Example: 11:08:20.488
 * @returns string
 */
export const getTimeStampNow = (): string =>
  new Date().toISOString().split('T')[1].slice(0, -1)

export const formatDate = (
  maybeDate: Date | GridGroupingValueGetterParams | GridValueFormatterParams,
  dateFormat: string,
  timeZone = 'UTC'
): string => {
  if (!maybeDate) {
    return '-'
  }
  if (maybeDate instanceof Date) {
    return formatInTimeZone(maybeDate, timeZone, dateFormat)
  }
  // GridGroupingValueGetterParams | GridValueFormatterParams
  if (!maybeDate.value) {
    return '-'
  }
  const date = new Date(maybeDate.value)
  return formatInTimeZone(date, timeZone, dateFormat)
}

export function toCetDateOrDefault(maybeDate: Date | string): Date | '' {
  if (!maybeDate) return ''

  const date = typeof maybeDate === 'string' ? parseISO(maybeDate) : maybeDate

  // if the date is not a valid date string return an empty string
  if (isNaN(date.getTime())) return ''

  // adjust the date to the local time zone (Amsterdam)
  const zonedDate = utcToZonedTime(date, 'CET')

  return zonedDate
}

export function toCetDateStringOrDefault(
  maybeDate: Date | string,
  dateFormat: string
): string {
  if (!maybeDate) return ''

  const date = typeof maybeDate === 'string' ? parseISO(maybeDate) : maybeDate

  // if the date is not a valid date string return an empty string
  if (isNaN(date.getTime())) return ''

  return date ? formatInTimeZone(date, 'CET', dateFormat) : ''
}
