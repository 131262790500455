import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Container, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'

import {
  allGridsDefaults,
  Entity,
  getCurrentPaginationModel,
  getCurrentSortModel,
  useFetchMany,
  useGridStore,
  useUserStore,
} from 'src/common'
import { Divider } from 'src/common/components/Divider'
import { ThemeConfig } from 'src/common/config/SpacingConfig'
import { IBuyersCardView } from 'src/common/services/client'
import { getOrderBy, getSkip, getTop } from 'src/common/utils/ODataHelpers'

import BuyersCardsFilter from '../components/BuyersCardsFilter'
import BuyersCardsGrid from '../components/BuyersCardsGrid'

export default function BuyersCards(): JSX.Element {
  const { CACHE_KEY } = allGridsDefaults.buyersCards
  const ENABLED = true as const
  const { t } = useTranslation()
  const { user } = useUserStore()
  const { setGridChanged, setShouldPaginationBeReset } = useGridStore()
  const [filterString, setFilterString] = useState<string>(undefined)

  const {
    data: buyerscards,
    isLoading,
    refetch,
  } = useFetchMany<IBuyersCardView>(
    {
      top: getTop(getCurrentPaginationModel(CACHE_KEY)),
      skip: getSkip(getCurrentPaginationModel(CACHE_KEY)),
      filter: filterString || undefined,
      count: true,
      orderby: getOrderBy(getCurrentSortModel(CACHE_KEY)),
    },
    Entity.BuyersCardView,
    ENABLED
  )

  const updateFilterString = (value: string) => {
    setFilterString(value)
    setShouldPaginationBeReset(true)
    setGridChanged('buyersCards')
  }

  const resetFilterString = () => {
    setFilterString(undefined)
    setShouldPaginationBeReset(true)
    setGridChanged('buyersCards')
  }

  return (
    <Container maxWidth='xl' sx={{ maxWidth: '1920px !important' }}>
      <Grid
        container
        spacing={2}
        sx={{
          width: '100%',
          margin: '0',
        }}
      >
        <Grid size={{ sm: 12 }} sx={{ padding: '0 !important' }}>
          <Typography
            variant={'h1'}
            sx={{
              fontSize: '2.5rem',
              my: `${ThemeConfig.spacing.sm * 8}px`,
            }}
          >
            {user.isAccountManagement || user.isApplicationManager
              ? t('common.titles.buyersCards')
              : t('common.titles.buyersCardsReadOnly')}
          </Typography>
        </Grid>
        <Grid size={{ sm: 12 }} mt={ThemeConfig.spacing.m}>
          <BuyersCardsFilter
            changeFilterString={updateFilterString}
            resetFilterString={resetFilterString}
          />
        </Grid>
        <Divider {...{ marginTop: 2, width: '100%' }} />
        <Grid size={{ sm: 12 }} mt={2}>
          <BuyersCardsGrid
            rows={buyerscards?.records ?? []}
            isLoading={isLoading}
            refresh={refetch}
            rowCount={
              buyerscards?.count ? Number(buyerscards?.count.toString()) : 0
            }
          />
        </Grid>
      </Grid>
    </Container>
  )
}
