import {
  IBuyersCardView,
  IInkoperPlaatView,
  IInkoperView,
  Vestiging,
} from 'src/common/services/client'

import { containsLocation, getUniqueCardNumbers, hasLocation } from '../lib'
import { InkoperPlaatRow } from '../types'

export const useRows = (
  inkoper: IInkoperView,
  platen: IInkoperPlaatView[],
  koperplaten: IBuyersCardView[]
): InkoperPlaatRow[] => {
  const plateNumbers = getUniqueCardNumbers(platen || [])

  return plateNumbers.map(pn => {
    const subSet = platen.filter(p => p.koperPlaatNummer === pn)
    const buyerscard = koperplaten.find(k => k.buyersCardId === pn)
    return {
      inkoperIdentificatie: inkoper?.inkoperIdentificatie ?? '',
      koperPlaatNummer: pn,
      koperNummer: buyerscard?.customerRfhRelationId ?? undefined,
      koperNaam: buyerscard?.customrRfhRelationName ?? '',
      blokkeerIndicatie: buyerscard?.blockedindication ?? false,
      aalsmeer: containsLocation(subSet, Vestiging.Aalsmeer),
      aalsmeerDisabled: !hasLocation(buyerscard, Vestiging.Aalsmeer),
      naaldwijk: containsLocation(subSet, Vestiging.Naaldwijk),
      naaldwijkDisabled: !hasLocation(buyerscard, Vestiging.Naaldwijk),
      rijnsburg: containsLocation(subSet, Vestiging.Rijnsburg),
      rijnsburgDisabled: !hasLocation(buyerscard, Vestiging.Rijnsburg),
      eelde: containsLocation(subSet, Vestiging.Eelde),
      eeldeDisabled: !hasLocation(buyerscard, Vestiging.Eelde),
    } as InkoperPlaatRow
  })
}
