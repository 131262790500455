import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { UpsertMode, UpsertModeType } from 'src/common/lib'

import { determineUpsertMode, getParam } from './lib'

type ReturnType = {
  ADDING: boolean
  upsertMode: UpsertModeType
  urlParam: number
}

export const usePathname = (rootPath: string): ReturnType => {
  const location = useLocation()
  const upsertMode = useMemo(
    () => determineUpsertMode(rootPath, location.pathname),
    [location, rootPath]
  )

  const ADDING = useMemo(() => upsertMode === UpsertMode.add, [upsertMode])

  const urlParam = useMemo(() => {
    if (upsertMode === UpsertMode.edit) {
      return getParam(rootPath, location.pathname)
    }
    if (upsertMode === UpsertMode.add) return 0
    return undefined
  }, [location.pathname, rootPath, upsertMode])

  return {
    ADDING,
    upsertMode,
    urlParam,
  }
}
