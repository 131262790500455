/* eslint-disable id-blacklist */
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import Grid from '@mui/material/Grid2'
import { SearchIcon } from '@rfh-core/icons'

import type { ApiClientSearchByValueFilter } from 'src/common/hooks'
import { Entity, useSearchManyByValue } from 'src/common/hooks'

type BuyersCardsDialogAvailablePlatesProps = {
  onChangePlate: (evt: any) => void
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const BuyersCardsDialogAvailablePlates: FC<
  BuyersCardsDialogAvailablePlatesProps
> = ({ onChangePlate }) => {
  /*
   * Hooks
   */
  const { t } = useTranslation()
  const { handleSubmit } = useForm()
  const [openPlate, setOpenPlate] = useState(false)
  const [searchStringPlate, setSearchStringPlate] = useState(0)
  const [filter, setFilter] = useState<ApiClientSearchByValueFilter>({
    key: 0,
  })
  const {
    data: cards,
    isLoading,
    refetch,
  } = useSearchManyByValue<string[]>(filter, Entity.BuyersCard, openPlate)

  /*
   * Methods
   */
  const handleClickOpen = () => {
    setOpenPlate(true)
  }

  const handleClose = () => {
    setOpenPlate(false)
  }

  const itemSelected = (evt: any) => {
    onChangePlate(evt)
    handleClose()
  }

  const handleUserKeyPress = (e: any) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      handleSubmit(onSubmit)()
    }
  }

  const onSubmit = () => {
    const apiClientFilter: ApiClientSearchByValueFilter = {
      key: searchStringPlate,
    }

    setFilter({ ...apiClientFilter })
  }

  const handleFilterBuyerNumber = (evt: any): void => {
    setSearchStringPlate(evt.target.value)
  }

  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /*
   * Render
   */
  return (
    <>
      <IconButton onClick={handleClickOpen} size='large' sx={{ mr: 0 }}>
        <SearchIcon />
      </IconButton>
      <Dialog open={openPlate} onClose={handleClose}>
        <DialogContent>
          <Typography style={{ marginBottom: '16px' }} variant={'h5'}>
            {t('common.titles.addBuyersCards')}
          </Typography>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <TextField
              id='koperplaatnummer'
              name='koperplaatnummer'
              type='number'
              fullWidth
              placeholder={t('buyersCards.addBuyersCards.fromPlateNumber')}
              onChange={handleFilterBuyerNumber}
              onKeyDown={handleUserKeyPress}
            />
            <Button
              variant='contained'
              onClick={handleSubmit(onSubmit)}
              disabled={isLoading}
            >
              {t('common.search')}
            </Button>
          </form>
          <Grid
            container
            spacing={2}
            sx={{
              marginTop: '16px',
              minHeight: '200px',
            }}
          >
            {cards?.length === 0 ? (
              <Grid size={{ xs: 12 }}>{t('common.noResults')}</Grid>
            ) : (
              cards?.map((plate: string) => (
                <Grid key={plate} size={{ xs: 3 }}>
                  <button
                    onClick={() =>
                      itemSelected({
                        target: {
                          name: 'buyersCardId',
                          value: plate,
                        },
                      })
                    }
                    style={{
                      width: '100%',
                      background: 'none',
                      color: 'inherit',
                      border: 'none',
                      padding: 0,
                      outline: 'none',
                      cursor: 'pointer',
                    }}
                  >
                    <Chip label={plate} color='info' sx={{ width: '100%' }} />
                  </button>
                </Grid>
              ))
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' autoFocus onClick={handleClose}>
            {t('common.back')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
