import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'

import { getFuncByEntity } from 'src/common/lib'
import { useApiClient } from 'src/common/providers'

import {
  ApiClientListByValueFilter,
  ApiClientListByValueFuncType,
  EntityType,
  FuncSort,
  ListAndCount,
} from '.'
import { hasPropertyAndNotNull } from './own/lib.utils'

export const useFetchManyByValue = <T>(
  filter: ApiClientListByValueFilter,
  entity: EntityType,
  enabled: boolean,
  extraOptions?: Partial<UseQueryOptions>
): UseQueryResult<ListAndCount<T>> => {
  const { apiClient } = useApiClient()
  const func = getFuncByEntity(
    FuncSort.list,
    entity
  ) as ApiClientListByValueFuncType
  const key = typeof filter.key === 'string' ? filter.key : `${filter.key}`
  const queryKey = [entity, FuncSort.list, key]
  const queryResult = useQuery({
    queryKey,
    queryFn: () => apiClient[func](key),
    enabled:
      hasPropertyAndNotNull(apiClient, func) && key !== undefined && enabled,
    keepPreviousData: true,
    placeholderData: {
      records: [],
      count: 0,
    },
    select: (result: any) => ({
      records: result.cleanedResponse || [],
      count: result['@odata.count'] ?? 0,
    }),
    ...extraOptions,
  })

  return queryResult as UseQueryResult<ListAndCount<T>>
}
