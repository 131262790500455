import type { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium'
import { AlertOIcon } from '@rfh-core/icons'
import theme from '@rfh-core/theme'

import {
  formatDate,
  formatNumber,
  toCetDateOrDefault,
  toCetDateStringOrDefault,
} from 'src/common'
import i18n from 'src/i18n'

export const isInputValid = (name: string, value: string | Date): boolean => {
  switch (name) {
    case 'inkoperLimiet':
      return /^\d{0,6}$/.test(value as string)
    case 'endDate':
      return !isNaN((value as Date).getTime())
    default:
      return true
  }
}

export const getPurchaserLimitsColumns = (): GridColDef[] => [
  {
    field: 'userAccountName',
    headerName: i18n.t('purchaser.purchaserIdentification'),
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
  {
    field: 'customerAdministrationNumber',
    headerName: i18n.t('purchaser.buyerNumber'),
    type: 'number',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    valueFormatter: row => formatNumber(row),
  },
  {
    field: 'customerName',
    headerName: i18n.t('purchaser.buyerName'),
    headerAlign: 'left',
    align: 'left',
    flex: 3,
  },
  {
    field: 'userAccountDayLimitAmount',
    headerName: i18n.t('purchaser.dayLimitAmount'),
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
  {
    field: 'startDateTime',
    headerName: i18n.t('purchaser.dayLimitStartDateTime'),
    headerAlign: 'center',
    type: 'date',
    align: 'center',
    flex: 1,
    valueGetter: ({ value }) => toCetDateOrDefault(value),
    valueFormatter: ({ value }) =>
      toCetDateStringOrDefault(value, i18n.t('common.dateFormatShort')),
    renderCell: ({ value }) =>
      formatDate(value, i18n.t('common.dateFormatShort'), 'CET'),
  },
  {
    field: 'endDateTime',
    headerName: i18n.t('purchaser.dayLimitEndDateTime'),
    headerAlign: 'center',
    type: 'date',
    align: 'center',
    flex: 1,
    valueGetter: ({ value }) => toCetDateOrDefault(value),
    valueFormatter: ({ value }) =>
      toCetDateStringOrDefault(value, i18n.t('common.dateFormatShort')),
    renderCell: ({ value }) =>
      formatDate(value, i18n.t('common.dateFormatShort'), 'CET'),
  },
  {
    field: 'isUserAccountBlocked',
    headerName: i18n.t('purchaser.blocked'),
    headerAlign: 'center',
    align: 'center',
    type: 'boolean',
    flex: 0.8,
    valueFormatter: ({ value }) => value, // Needed for csv exports to supply the boolean as `Yes` and `No`, it uses the value from the valueGetter
    valueGetter: ({ value }) =>
      value ? i18n.t('common.yes') : i18n.t('common.no'), // Needed for excel exports to supply the boolean as `Yes` and `No`
    renderCell: (params: GridRenderCellParams) =>
      params.row.isUserAccountBlocked ? (
        <AlertOIcon sx={{ color: `${theme.rfhColors.alertRed}` }} />
      ) : (
        '-'
      ),
  },
  {
    field: 'blockedDate',
    headerName: i18n.t('purchaser.blockingDateTime'),
    headerAlign: 'center',
    type: 'date',
    align: 'center',
    flex: 1.5,
    valueGetter: ({ value }) => toCetDateOrDefault(value),
    valueFormatter: ({ value }) =>
      toCetDateStringOrDefault(value, i18n.t('common.dateFormatShort')),
    renderCell: ({ value }) =>
      formatDate(value, i18n.t('common.dateFormatShort'), 'CET'),
  },
]
