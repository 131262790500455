import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'

import { noOp } from 'src/common/lib'
import { useApiClient } from 'src/common/providers'

import { getFuncByEntity } from './own/lib.map'
import {
  ApiClientListFuncType,
  EntityType,
  FuncSort,
} from './own/lib.resources'
import { hasPropertyAndNotNull, prefetch } from './own/lib.utils'
import { ApiClientListFilter, ListAndCount } from './own/types'

export const useFetchMany = <T>(
  queryFilter: ApiClientListFilter,
  entity: EntityType,
  enabled = true,
  extraOptions?: Partial<UseQueryOptions>
): UseQueryResult<ListAndCount<T>, unknown> => {
  const { apiClient } = useApiClient()
  const func = getFuncByEntity(FuncSort.list, entity) as ApiClientListFuncType
  const queryClient = useQueryClient()
  const queryResult = useQuery({
    queryKey: [
      entity,
      FuncSort.list,
      queryFilter?.count,
      queryFilter?.top,
      queryFilter?.skip,
      queryFilter?.filter,
      queryFilter?.orderby,
      queryFilter?.expand,
      hasPropertyAndNotNull(apiClient, func),
    ],
    queryFn: () =>
      (apiClient?.[func](
        queryFilter?.filter ?? undefined,
        queryFilter?.orderby ?? undefined,
        queryFilter?.top ?? undefined,
        queryFilter?.skip ?? undefined,
        queryFilter?.count ?? undefined,
        queryFilter?.expand ?? undefined
      ) as Promise<any>) ?? noOp,
    enabled:
      enabled && Boolean(apiClient) && hasPropertyAndNotNull(apiClient, func),
    keepPreviousData: true,
    placeholderData: {
      records: [],
      count: 0,
    },
    select: (result: any) => {
      if (!result) return { records: [] as T[], count: 0 }
      return {
        records: result?.value || ([] as T[]),
        count: result['@odata.count'] ?? 0,
      }
    },
    ...extraOptions,
  }) as UseQueryResult<ListAndCount<T>, unknown>

  if (queryFilter && queryResult.isPreviousData && queryResult.isSuccess) {
    prefetch(queryResult, queryFilter, entity, func, apiClient, queryClient)
  }

  return queryResult
}
