import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

import { replaceAllEmptyValuesWithUndefined } from 'src/common'
import {
  IInkoper,
  IInkoperKlokView,
  IInkoperPlaatView,
  IInkoperView,
  ILocatie,
  Vestiging,
} from 'src/common/services/client'

import { removeExtraIInkoperViewProperties } from '../lib'

type StoreState = {
  mustRefetchPurchaser: boolean
  mustRefetchPurchaserCards: boolean
  oldPurchaser: IInkoperView
  purchaser: IInkoperView
  purchaserCards: IInkoperPlaatView[]
  userId: string
  locations: ILocatie[]
  purchaserClocks: IInkoperKlokView[]
  openFloridaySearch: boolean
  openFloridayBuyerSearch: boolean
}

type Actions = {
  /* store */
  resetPurchaserState: () => void
  setUserId: (id: string) => void
  /* computables */
  getCardsByPlateNumber: (
    plateNumber: number,
    vestiging?: Vestiging
  ) => IInkoperPlaatView[]
  isPurchaserDirty: () => boolean
  /* purchaser */
  setMustRefetchPurchaser: (input: boolean) => void
  setOldPurchaser: (op: IInkoperView) => void
  setPurchaser: (p: IInkoperView) => void
  updatePurchaser: (np: Partial<IInkoperView>) => void
  /* their cards */
  setMustRefetchPurchaserCards: (input: boolean) => void
  setPurchaserCards: (pcs: IInkoperPlaatView[]) => void
  addPurchaserCard: (npc: IInkoperPlaatView) => void
  removePurchaserCard: (id: number) => void
  setLocations: (l: ILocatie[]) => void
  setPurchaserClocks: (pc: IInkoperKlokView[]) => void
  isInTheList: (buyerCard: number) => boolean
  setOpenFloridaySearch: (input: boolean) => void
  setOpenFloridayBuyerSearch: (input: boolean) => void
}

const storeName = 'PurchaserStore'

const initialStoreState: StoreState = {
  mustRefetchPurchaser: false,
  mustRefetchPurchaserCards: false,
  oldPurchaser: null,
  purchaser: null,
  purchaserCards: [],
  userId: '',
  locations: [],
  purchaserClocks: [],
  openFloridaySearch: false,
  openFloridayBuyerSearch: false,
}

export const usePurchaserStore = create<StoreState & Actions>()(
  devtools(
    persist(
      (set, get: () => any) => ({
        ...initialStoreState,
        /* store */
        resetPurchaserState: () =>
          set(
            () => initialStoreState,
            false, // replace or not replace - that is the question!
            'resetPurchaserState'
          ),
        setUserId: (id: string) =>
          set(
            () => ({
              userId: id,
            }),
            false,
            'setUserId'
          ),
        /* computables */
        getCardsByPlateNumber: (plateNumber: number, vestiging?: Vestiging) => {
          const filtered = get().purchaserCards.filter(
            (p: IInkoperPlaatView) => {
              const result = p.koperPlaatNummer === plateNumber
              const result1 = vestiging ? p.vestigingCode === vestiging : true
              return result && result1
            }
          )
          return filtered
        },
        isPurchaserDirty: () => {
          const oldOne: IInkoper = removeExtraIInkoperViewProperties({
            ...get().oldPurchaser,
          })
          delete oldOne.aanmaakGebruiker
          delete oldOne.aanmaakDatumTijd
          delete oldOne.mutatieGebruiker
          delete oldOne.mutatieDatumTijd
          delete oldOne.vervallenDatumTijd
          const first = replaceAllEmptyValuesWithUndefined(
            oldOne as unknown as Record<string, unknown>
          )
          const newOne: IInkoper = removeExtraIInkoperViewProperties({
            ...get().purchaser,
          })
          delete newOne.aanmaakGebruiker
          delete newOne.aanmaakDatumTijd
          delete newOne.mutatieGebruiker
          delete newOne.mutatieDatumTijd
          delete newOne.vervallenDatumTijd
          const second = replaceAllEmptyValuesWithUndefined(
            newOne as unknown as Record<string, unknown>
          )
          return JSON.stringify(first) !== JSON.stringify(second)
        },
        /* purchaser */
        setMustRefetchPurchaser: (input: boolean) =>
          set(
            () => ({
              mustRefetchPurchaser: input,
            }),
            false,
            'setMustRefetchPurchaser'
          ),
        setOldPurchaser: (op: IInkoperView) =>
          set(
            () => ({
              oldPurchaser: { ...op, inkoperPlaten: [] as IInkoperPlaatView[] },
            }),
            false,
            'setOldPurchaser'
          ),
        setPurchaser: (p?: IInkoperView) =>
          set(
            () => ({
              purchaser: p
                ? {
                    ...p,
                    inkoperPlaten: [] as IInkoperPlaatView[],
                  }
                : null,
            }),
            false,
            'setPurchaser'
          ),
        updatePurchaser: (np: Partial<IInkoperView>) =>
          set(
            (state: StoreState) => ({
              purchaser: { ...state.purchaser, ...np },
            }),
            false,
            'updatePurchaser'
          ),
        /* their cards */
        setMustRefetchPurchaserCards: (input: boolean) =>
          set(
            () => ({
              mustRefetchPurchaserCards: input,
            }),
            false,
            'setMustRefetchPurchaserCards'
          ),
        setPurchaserCards: (pcs: IInkoperPlaatView[]) =>
          set(
            () => ({
              purchaserCards: [...pcs],
            }),
            false,
            'setPurchaserCards'
          ),
        addPurchaserCard: (npc: IInkoperPlaatView) =>
          set(
            (state: StoreState) => ({
              purchaserCards: [...state.purchaserCards, npc],
            }),
            false,
            'addPurchaserCard'
          ),
        removePurchaserCard: (id: number) =>
          set(
            (state: StoreState) => ({
              purchaserCards: [
                ...state.purchaserCards.filter(
                  npc => npc?.inkoperPlaatID !== id
                ),
              ],
            }),
            false,
            'removePurchaserCard'
          ),
        setLocations: (l: ILocatie[]) =>
          set(
            () => ({
              locations: l,
            }),
            false,
            'setLocations'
          ),
        setPurchaserClocks: (pc: IInkoperKlokView[]) =>
          set(
            () => ({
              purchaserClocks: [...pc],
            }),
            false,
            'setPurchaserClocks'
          ),
        /* computable functions */
        isInTheList: (buyerCard: number) =>
          get()
            .purchaserCards.map((pc: IInkoperPlaatView) => pc.koperPlaatNummer)
            .includes(buyerCard),
        setOpenFloridaySearch: (input: boolean) =>
          set(
            () => ({
              openFloridaySearch: input,
            }),
            false,
            'setOpenFloridaySearch'
          ),
        setOpenFloridayBuyerSearch: (input: boolean) =>
          set(
            () => ({
              openFloridayBuyerSearch: input,
            }),
            false,
            'setOpenFloridayBuyerSearch'
          ),
      }),
      {
        name: 'purchaser-storage',
        storage: createJSONStorage(() => sessionStorage),
      }
    ),
    { name: storeName }
  )
)
