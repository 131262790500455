import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import { initReactI18next } from 'react-i18next'

import { customLanguageDetector } from './customLanguageDetector'
import deTranslations from './de/translations.json'
import enTranslations from './en/translations.json'
import nlTranslations from './nl/translations.json'

export enum AppLanguageCode {
  nl = 'nl',
  en = 'en',
  de = 'de',
}
export type SupportedLanguage =
  | AppLanguageCode.nl
  | AppLanguageCode.en
  | AppLanguageCode.de
export const defaultNS = 'translations' // should be the same as the file name
export const resources = {
  nl: { translations: nlTranslations },
  en: { translations: enTranslations },
  de: { translations: deTranslations },
} as const
export type AppNamespace = Record<'translations', any> // should be the same as the file name
export const fallbackLng = AppLanguageCode.nl
export const locales = [
  AppLanguageCode.nl,
  AppLanguageCode.en,
  AppLanguageCode.de,
]

export const supportedLanguages = Object.keys(resources)

const languageDetector = new LanguageDetector()
languageDetector.addDetector(customLanguageDetector)

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    load: 'languageOnly',
    resources,
    fallbackLng,
    keySeparator: '.',
    cache: { enabled: true },
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    defaultNS,
    ns: [defaultNS] as (keyof AppNamespace)[], // for now, there exists only one ns
    detection: {
      caches: ['localStorage', 'customLanguageDetector'],
      order: ['localStorage', 'customLanguageDetector'],
    },
    react: {
      useSuspense: true,
    },
    debug: !process.env.NODE_ENV || process.env.NODE_ENV === 'development',
  })

export default i18n
