import { Suspense, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium'
import { DeleteIcon } from '@rfh-core/icons'
import theme from '@rfh-core/theme'

import {
  allGridsDefaults,
  CustomDataGrid,
  getCurrentSortModel,
  getOrderBy,
  getToolbarProps,
} from 'src/common'
import { ApiClientListFilter, Entity, useFetchMany } from 'src/common/hooks'
import { IBuyersCardView, Vestiging } from 'src/common/services/client'

import { getPurchaserCardsGridColumns } from '../constants/constants'
import { useRows } from '../hooks'
import {
  byPlateNumber,
  getUniqueCardNumbers,
  isLastLocationToBeRemoved,
} from '../lib'
import { useCardRowsStore, usePurchaserStore } from '../stores'
import { InkoperPlaatRow } from '../types'
import { DeletePurchaserCardDialog } from './DeletePurchaserCardDialog'
import { PurchaserCardsSkeleton } from './skeletons'

export default function PurchaserCardsGrid(): JSX.Element {
  const { CACHE_KEY } = allGridsDefaults.purchaserCards
  const { i18n, t } = useTranslation()
  const { purchaser, purchaserCards } = usePurchaserStore()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [idToBeDeleted, setIdToBeDeleted] = useState<number>(0)
  const plateNumbers = getUniqueCardNumbers(purchaserCards || [])
  const filter: ApiClientListFilter = {
    filter: `buyerscardId in (${plateNumbers.join(',')})`,
    orderby: getOrderBy(getCurrentSortModel(CACHE_KEY)),
  }
  const {
    cardRows,
    setCardRows,
    setMustRebuildCardRows,
    setOldCardRows,
    updateCardRow,
  } = useCardRowsStore()
  const fileName = t('purchaser.purchaserCards')

  const buyerCardsResult = useFetchMany<IBuyersCardView>(
    filter,
    Entity.BuyersCardView,
    plateNumbers.length > 0
  )

  const getRowId = useCallback(
    (row: InkoperPlaatRow) => row.koperPlaatNummer,
    []
  )

  const openDeleteDialog = useCallback((value: InkoperPlaatRow) => {
    setOpenDelete(true)
    setIdToBeDeleted(value.koperPlaatNummer)
  }, [])

  const closeDeleteDialog = useCallback(() => {
    setOpenDelete(false)
  }, [])

  const handleDeleteClick = useCallback(
    (row: InkoperPlaatRow) => (): void => {
      openDeleteDialog(row)
    },
    [openDeleteDialog]
  )

  const handleCheckboxClick = useCallback(
    (row: InkoperPlaatRow, vestiging: Vestiging): void => {
      const newRow = { ...row }
      newRow[vestiging] = !row[vestiging]
      if (isLastLocationToBeRemoved(newRow)) {
        openDeleteDialog(row)
      } else {
        updateCardRow(newRow)
      }
    },
    [openDeleteDialog, updateCardRow]
  )

  const getColumns = (): GridColDef[] => {
    const staticColumns = [
      ...getPurchaserCardsGridColumns({
        onCheckboxClick: handleCheckboxClick,
        disabled: purchaser?.isVervallen,
      }),
    ]

    staticColumns.push({
      field: 'delete',
      disableColumnMenu: true,
      type: 'actions',
      hideable: false,
      sortable: false,
      renderHeader: () => (
        <DeleteIcon
          sx={{
            color: theme.rfhColors.white,
            width: '20px',
            height: '20px',
          }}
        />
      ),
      renderCell: (params: GridRenderCellParams) =>
        !purchaser?.isVervallen ? (
          <DeleteIcon
            onClick={handleDeleteClick(params.row)}
            data-testid='delete'
            sx={{
              cursor: 'pointer',
              color: theme.rfhColors.grey[800],
              width: '20px',
              height: '20px',
            }}
          />
        ) : (
          <DeleteIcon
            data-testid='delete'
            sx={{
              color: theme.rfhColors.grey[200],
              width: '20px',
              height: '20px',
            }}
          />
        ),
      maxWidth: 50,
    })

    return staticColumns
  }

  const tempRows = useRows(
    purchaser,
    purchaserCards,
    buyerCardsResult?.data?.records
  )

  useEffect(() => {
    const ensureCardRows = async () => {
      if (
        isLoading ||
        !purchaser ||
        buyerCardsResult.isLoading ||
        buyerCardsResult.data.records.length === 0
      ) {
        return Promise.resolve()
      }

      tempRows.sort(byPlateNumber)
      if (cardRows.length === 0) {
        setCardRows(tempRows)
      }
      setOldCardRows(tempRows)
      setCardRows(tempRows)
      setMustRebuildCardRows(false)
      setIsLoading(false)
    }

    if (purchaserCards.length > 0) {
      ensureCardRows()
    } else {
      setCardRows([])
    }
    //! don't add tempRows to deps!!!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    purchaser?.inkoperID,
    purchaserCards,
    buyerCardsResult.data.records,
    isLoading,
    purchaser,
    buyerCardsResult.isLoading,
    cardRows.length,
    setOldCardRows,
    setCardRows,
    setMustRebuildCardRows,
  ])

  return (
    <Suspense fallback={<PurchaserCardsSkeleton />}>
      <CustomDataGrid
        columns={getColumns()}
        getRowId={getRowId}
        gridName={'purchaserCards'}
        language={i18n.language}
        rows={cardRows}
        gridProps={{
          rowCount: cardRows?.length,
          rowHeight: 44,
          loading: isLoading,
          pagination: false,
          sx: { zIndex: 100 },
        }}
        toolbarProps={getToolbarProps(fileName)}
        sortingMode={'client'}
      />
      <DeletePurchaserCardDialog
        idToBeDeleted={idToBeDeleted}
        onCancel={closeDeleteDialog}
        onClose={closeDeleteDialog}
        open={openDelete}
      />
    </Suspense>
  )
}
