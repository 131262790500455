import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Button,
  FormControl,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import Grid from '@mui/material/Grid2'

import { ELEMENT_HEIGHT, MAX_INPUT_WIDTH, noOp } from 'src/common'
import AvailableLocations from 'src/common/components/AvailableLocations'
import CustomerInput from 'src/common/components/CustomerInput'
import LabelBooleanDropdown from 'src/common/components/LabelBooleanDropdown/LabelBooleanDropdown'
import { ThemeConfig } from 'src/common/config/SpacingConfig'
import { useFilter } from 'src/common/hooks'
import {
  type IBuyersCardsFilter,
  type ISearchFormInput,
} from 'src/common/interfaces'
import { constructFilterString } from 'src/common/utils'

import { generateFilterString } from '../lib'

export default function BuyersCardsFilter({
  changeFilterString,
  resetFilterString,
}: Readonly<IBuyersCardsFilter>): JSX.Element {
  const keyBuyersCardsFilterValue = 'BuyersCardsFilter-values'
  const defaultFilter: ISearchFormInput = {
    customerRfhRelationId: undefined,
    customrRfhRelationName: undefined,
    buyersCardId: undefined,
    vestiging: '',
    isVervallen: undefined,
    blockedindication: undefined,
  }
  const { t } = useTranslation()

  const setFilterString = (newFilter: ISearchFormInput) => {
    const newFilterString = constructFilterString(
      newFilter,
      generateFilterString
    )
    changeFilterString(newFilterString)
  }

  const { filter, clear, handleChange, handleRequest } =
    useFilter<ISearchFormInput>({
      defaultFilter,
      resetFilterString,
      setFilterString,
      cacheKey: keyBuyersCardsFilterValue,
    })

  const handleInputChange = (evt: any): void => {
    const { name, value } = evt.target
    handleChange(name as keyof ISearchFormInput, value)
  }

  const handleInputChangeNumber = (evt: any): void => {
    const { name, value } = evt.target
    const parsedValue = value ? parseInt(value, 10) : undefined
    handleChange(name as keyof ISearchFormInput, parsedValue)
  }
  const handleInputChangeSelect = ({ target }: SelectChangeEvent<string>) => {
    const { name, value } = target
    const isDefined = ['true', 'false'].includes(value)
    const isTrue = isDefined && value === 'true'
    if (isDefined) {
      handleChange(name as keyof ISearchFormInput, isTrue)
    } else {
      handleChange(name as keyof ISearchFormInput, undefined)
    }
  }

  useEffect(() => {
    if (filter) {
      setFilterString(filter)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid
      container
      flexDirection={'row'}
      justifyContent={'space-between'}
      alignItems={'end'}
      mb={ThemeConfig.spacing.sm}
    >
      <Grid
        container
        flexDirection={'row'}
        justifyContent={'justify-start'}
        alignItems={'end'}
        gap={ThemeConfig.spacing.s}
      >
        <Grid maxWidth={MAX_INPUT_WIDTH}>
          <CustomerInput
            showOnlyAvailableBuyers={false}
            changeCustomerNumber={value =>
              handleChange('customerRfhRelationId', value)
            }
            changeValid={noOp}
            value={
              filter.customerRfhRelationId
                ? filter.customerRfhRelationId
                : undefined
            }
          />
        </Grid>
        <Grid maxWidth={MAX_INPUT_WIDTH}>
          <FormControl fullWidth>
            <TextField
              id='customrRfhRelationName'
              label={t('buyersCards.customerName')}
              name='customrRfhRelationName'
              placeholder={t('buyersCards.customerName')}
              value={
                filter.customrRfhRelationName
                  ? filter.customrRfhRelationName
                  : ''
              }
              onChange={handleInputChange}
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid maxWidth={MAX_INPUT_WIDTH}>
          <FormControl fullWidth>
            <TextField
              id='buyersCardId'
              label={t('buyersCards.plateNumber')}
              name='buyersCardId'
              placeholder={t('buyersCards.plateNumber')}
              value={filter.buyersCardId ? filter.buyersCardId : ''}
              onChange={handleInputChangeNumber}
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid minWidth={MAX_INPUT_WIDTH}>
          <AvailableLocations
            handleChange={handleChange}
            inputValue={filter.vestiging}
            labelVisible
          />
        </Grid>
        <Grid>
          <LabelBooleanDropdown
            onChange={handleInputChangeSelect}
            label={t('buyersCards.expired')}
            inputValue={filter.isVervallen}
            fieldname={'isVervallen'}
            labelVisible
          />
        </Grid>
        <Grid>
          <LabelBooleanDropdown
            label={t('buyersCards.blocked')}
            onChange={handleInputChangeSelect}
            inputValue={filter.blockedindication}
            fieldname={'blockedindication'}
            labelVisible
          />
        </Grid>
      </Grid>
      <Box flexGrow={1} />
      <Grid
        container
        flexDirection={'row'}
        justifyContent={'justify-end'}
        alignItems={'end'}
        gap={ThemeConfig.spacing.s}
      >
        <Button
          variant='outlined'
          onClick={clear}
          sx={{ height: ELEMENT_HEIGHT }}
        >
          {t('common.clear')}
        </Button>
        <Button
          variant='contained'
          type='submit'
          onClick={handleRequest}
          sx={{ height: ELEMENT_HEIGHT }}
        >
          {t('common.search')}
        </Button>
      </Grid>
    </Grid>
  )
}
