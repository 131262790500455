import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import { CssBaseline } from '@mui/material'
import { setMuiLicenseKey } from '@rfh-core/theme'

import App from './App'
import { loadDevTools } from './dev-tools'
import './i18n'
import './index.css'

setMuiLicenseKey()

if ('serviceWorker' in navigator) {
  caches.keys().then(function (cacheNames) {
    cacheNames.forEach(function (cacheName) {
      caches.delete(cacheName)
    })
  })
}

const root = createRoot(document.getElementById('root') as HTMLElement)

const callbackToRun = (): void =>
  root.render(
    <StrictMode>
      <CssBaseline />
      <App />
    </StrictMode>
  )

loadDevTools(callbackToRun)
